import { defineComponent, reactive, toRefs } from 'vue';
import { useMessage } from 'naive-ui';
import useClipboard from "vue-clipboard3";
export default defineComponent({
  components: {},
  props: {
    bordered: {
      type: Boolean,
      default: true
    },
    placement: {
      type: String,
      default: "left"
    },
    column: {
      type: Number,
      default: 2
    },
    datas: {
      type: Array,
      default: []
    }
  },
  setup() {
    const message = useMessage();
    const {
      toClipboard
    } = useClipboard();
    const desc = reactive({
      labelStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "top"
      },
      contentStyle: {
        textAlign: "justify"
      },
      // copy
      copy: async value => {
        try {
          await toClipboard(value);
          message.success("复制成功", {
            duration: 1000
          });
        } catch (e) {
          console.error(e);
        }
      }
    });
    return {
      ...toRefs(desc)
    };
  }
});