import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9525a2aa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "axis"
};
const _hoisted_2 = {
  key: 0,
  class: "axis-box",
  ref: "ref"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.ready ? (_openBlock(), _createElementBlock("div", _hoisted_2, null, 512)) : _createCommentVNode("", true)]);
}