import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_n_menu = _resolveComponent("n-menu");
  const _component_n_layout_sider = _resolveComponent("n-layout-sider");
  const _component_n_layout = _resolveComponent("n-layout");
  const _component_n_space = _resolveComponent("n-space");
  return _openBlock(), _createBlock(_component_n_space, {
    vertical: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_n_layout, null, {
      default: _withCtx(() => [_createVNode(_component_n_layout, {
        "has-sider": ""
      }, {
        default: _withCtx(() => [_createVNode(_component_n_layout_sider, {
          bordered: "",
          "show-trigger": "",
          "collapse-mode": "width",
          "collapsed-width": 64,
          width: 240,
          "native-scrollbar": false,
          inverted: _ctx.inverted,
          style: {
            "max-height": "320px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_n_menu, {
            inverted: _ctx.inverted,
            "collapsed-width": 64,
            "collapsed-icon-size": 22,
            options: _ctx.menuOptions,
            value: _ctx.selectedKey,
            "onUpdate:value": _cache[0] || (_cache[0] = $event => _ctx.selectedKey = $event)
          }, null, 8, ["inverted", "options", "value"])]),
          _: 1
        }, 8, ["inverted"]), _createVNode(_component_n_layout, {
          style: {
            "max-height": "320px"
          }
        }), _renderSlot(_ctx.$slots, "default")]),
        _: 3
      })]),
      _: 3
    })]),
    _: 3
  });
}