import { defineComponent, reactive, toRefs } from 'vue';
export default defineComponent({
  components: {},
  props: {
    label: {
      type: String,
      default: ""
    },
    h: {
      type: Boolean,
      default: false
    },
    p: {
      type: Number,
      default: 10
    },
    cStyle: {
      type: Object,
      default: {}
    }
  },
  setup() {
    const box = reactive({});
    return {
      ...toRefs(box)
    };
  }
});