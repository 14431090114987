const routeComponent = (view: string) => {
  return (): unknown => import(`../view/${view}.vue`);
};

const routes = [
  {
    path: "/",
    name: "home",
    meta: { title: "首页", menu: false, icon: "home", loggedAuth: false },
    component: routeComponent("index/home"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
      loggedAuth: false,
      frame: false,
      keepAlive: false,
      menu: false,
    },
    component: routeComponent("account/login"),
  },
  {
    path: "/homeIndex",
    name: "homeIndex",
    meta: { title: "后台首页" },
    component: routeComponent("home/homeIndex"),
  },

  
  {
    path: "/report",
    name: "report",
    meta: { title: "个人中心" },
    component: routeComponent("index/report"),
  },
  
  {
    path: "/user",
    name: "user",
    meta: { title: "用户管理" },
    component: routeComponent("user/user"),
  },
  
  {
    path: "/power",
    name: "power",
    meta: { title: "角色管理" },
    component: routeComponent("user/power"),
  },
  {
    path: "/permission",
    name: "permission",
    meta: { title: "权限管理" },
    component: routeComponent("user/permission"),
  },
  
  {
    path: "/dept",
    name: "dept",
    meta: { title: "部门管理" },
    component: routeComponent("user/dept"),
  },
  
  {
    path: "/menu",
    name: "menu",
    meta: { title: "菜单管理" },
    component: routeComponent("user/menu"),
  },
  {
    path: "/platform",
    name: "platform",
    meta: { title: "平台管理" },
    component: routeComponent("user/platform"),
  },

  {
    path: "/post",
    name: "post",
    meta: { title: "岗位管理" },
    component: routeComponent("user/post"),
  },

  {
    path: "/record",
    name: "record",
    meta: { title: "更新日志" },
    component: routeComponent("user/record"),
  },
  
  {
    path: "/score",
    name: "score",
    meta: { title: "分数操作" },
    component: routeComponent("score/index"),
  },

  {
    path: "/recharge",
    name: "recharge",
    meta: { title: "充值记录" },
    component: routeComponent("score/recharge"),
  },
  {
    path: "/consumption",
    name: "consumption",
    meta: { title: "消费排名" },
    component: routeComponent("score/consumption"),
  },
  
  {
    path: "/scoreSelfList",
    name: "scoreSelfList",
    meta: { title: "个人分数记录" },
    component: routeComponent("score/scoreSelfList"),
  },
  
  {
    path: "/logList",
    name: "logList",
    meta: { title: "登录记录" },
    component: routeComponent("logList/logList"),
  },
  {
    path: "/logSelfList",
    name: "logSelfList",
    meta: { title: "个人登录记录" },
    component: routeComponent("logList/logSelfList"),
  },
  
  {
    path: "/searchSelfList",
    name: "searchSelfList",
    meta: { title: "个人查询记录" },
    component: routeComponent("logList/searchSelfList"),
  },
  
  {
    path: "/logNum",
    name: "logNum",
    meta: { title: "登录次数" },
    component: routeComponent("logList/logNum"),
  },

  {
    path: "/logMap",
    name: "logMap",
    meta: { title: "登录地图" },
    component: routeComponent("logList/logMap"),
  },

  {
    path: "/apiNum",
    name: "apiNum",
    meta: { title: "接口操作次数" },
    component: routeComponent("logList/apiNum"),
  },
  {
    path: "/searchCompany",
    name: "searchCompany",
    meta: { title: "企业搜索统计" },
    component: routeComponent("logList/searchCompany"),
  },
  {
    path: "/about",
    name: "about",
    meta: { title: "操作日志" },
    component: routeComponent("logList/about"),
  },
  
  {
    path: "/searchList",
    name: "searchList",
    meta: { title: "最近搜索公司列表" },
    component: routeComponent("logList/searchList"),
  },
  {
    path: "/pdfList",
    name: "pdfList",
    meta: { title: "导出报告数量统计" },
    component: routeComponent("logList/pdfList"),
  },
  {
    path: "/userOnle",
    name: "userOnle",
    meta: { title: "在线用户" },
    component: routeComponent("logList/userOnle"),
  },
  
  
  
  {
    path: "/cs",
    name: "cs",
    meta: { title: "cs" },
    component: routeComponent("user/cs"),
  },
  
];

export default routes;
