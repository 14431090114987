import { defineComponent, reactive, computed, watch, onMounted, toRefs } from 'vue';
import { useStore } from 'vuex';
import { getIndHs } from "@/util/industry";
export default defineComponent({
  components: {},
  props: {
    ind: {
      type: Number,
      default: undefined
    }
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const industry = reactive({
      ref: null,
      placeholder: "请选择行业",
      value: undefined,
      options: computed(() => {
        return store.getters.indh.length > 0 ? getIndHs() : [];
      }),
      update: (value, option) => {
        console.log(value, option);
        industry.confirm();
      },
      confirm: () => {
        emit("setIndh", industry.value);
        industry.ref.setShow(false);
      },
      reset: () => {
        industry.value = props.ind !== undefined ? props.ind : undefined;
      }
    });
    // 更新当前
    watch(() => props.ind, () => {
      industry.reset();
    });
    onMounted(() => {
      industry.reset();
    });
    return {
      ...toRefs(industry)
    };
  }
});