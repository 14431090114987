import { nextTick } from 'vue';
import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, shallowRef, toRefs } from "vue";
import * as echarts from "echarts";
export default defineComponent({
  components: {},
  props: {
    code: {
      type: Number,
      default: 100000
    },
    unit: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      default: {}
    }
  },
  setup(props, {
    emit
  }) {
    const chartBox = reactive({
      mapRef: null,
      mapCase: shallowRef(),
      mapGenerate: false,
      opts: {
        // grid: {
        //   left: 0,
        //   top: 0,
        //   right: 0,
        //   bottom: 0,
        // },
        visualMap: {
          min: 0,
          max: 0,
          show: true,
          type: 'piecewise',
          pieces: [{
            min: 50
          }, {
            min: 21,
            max: 50
          }, {
            min: 1,
            max: 20
          }],
          showLabel: true,
          right: '10%',
          bottom: '20%',
          // top: 'bottom',
          text: ['登录次数'],
          calculable: true,
          inRange: {
            // color: ['#3B5077', '#031525'] // 蓝黑
            // color: ['#ffc0cb', '#800080'] // 红紫
            // color: ['#3C3B3F', '#605C3C'] // 黑绿
            // color: ['#0f0c29', '#302b63', '#24243e'] // 黑紫黑
            // color: ['#23074d', '#cc5333'] // 紫红
            // color: ['#1488CC', '#A5CC82']//蓝红
            // color: ['#00467F', '#A5CC82'] // 蓝绿
            color: ['#A0C1FB', '#1488CC', '#2B32B2'] // 浅蓝
          }
        },

        // geo: {
        //     show: true,
        //     map: 'chinacity',
        //     label: {
        //         normal: {
        //             show: false,
        //         },
        //         emphasis: {
        //             show: false,
        //         }
        //     },
        //     roam: true,
        // },
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/>{c}(次)'
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              readOnly: false
            },
            restore: {},
            saveAsImage: {
              pixelRatio: 4
            }
          }
        },
        series: [{
          map: "ieb",
          name: '登录次数',
          type: 'map',
          mapType: 'chinacity',
          label: {
            normal: {
              show: false
            }
          },
          roam: true,
          // itemStyle: {
          //     normal: {
          //         borderColor: '#CD4F39',
          //         areaColor: '#54FF9F',
          //         borderWidth: 4,
          //         opacity: 0.2
          //     }
          // },
          data: []
        }]
      },
      load: async code => {
        return new Promise(resolve => {
          // fetch(`${process.env.BASE_URL}static/map/${code}.geoJson`)data
          fetch(`${process.env.BASE_URL}static/map/china-cities.json`).then(res => res.json()).then(data => {
            data.features.forEach(item => {
              // item.properties.cp = item.properties.center;
            });
            resolve(data);
          });
        });
      },
      process: () => {
        const data = props.data;
        // console.log(data)
        const datas = [];
        data.forEach((item, idx) => {
          const mapItem = {
            name: item.name,
            value: item.value
          };
          datas.push(mapItem);
        });
        chartBox.opts.series[0].data = datas;
        // max
        if (datas.length > 0) {
          const max = Math.max.apply(Math, datas.map(item => item.value));
          chartBox.opts.visualMap.max = Math.ceil(max / 1000) * 1000;
        }
      },
      draw: async () => {
        nextTick(() => {
          chartBox.process();
        });
        const code = props.code;
        // map config
        // chartBox.opts.series[0].layoutCenter =
        //   code === 100000 ? ["50%", "68%"] : ["50%", "50%"];
        // chartBox.opts.series[0].layoutSize = code === 100000 ? "125%" : "100%";
        // map
        const map = await chartBox.load(code);
        echarts.registerMap("ieb", map);
        if (chartBox.mapRef && chartBox.mapCase) {
          // 防止多层重复
          echarts.dispose(chartBox.mapCase);
        }
        // main
        chartBox.mapCase = echarts.init(chartBox.mapRef);
        chartBox.mapCase.setOption(chartBox.opts, true);
        // window resize
        window.addEventListener("resize", () => {
          chartBox.refresh();
        });
        // box resize
        const observer = new ResizeObserver(() => {
          chartBox.refresh();
        });
        observer.observe(chartBox.mapRef);
        // click
        chartBox.mapCase.on("click", e => {
          // const code: number = props.code;
          // if (code === 100000) {
          // }
          const area = map.features.find(item => item.properties.name === e.name);
          if (area) {
            const noDataArea = [810000, 820000, 710000];
            const code = area.id;
            if (noDataArea.indexOf(code) === -1) {
              emit("change", {
                name: area.properties.name,
                code: code,
                city: area.properties.city
              });
            }
          }
        });
        // dblclick
        chartBox.mapCase.on("dblclick", e => {
          const code = props.code;
          if (code !== 100000) {
            emit("change", 100000);
          }
        });
      },
      refresh: () => {
        chartBox.mapCase.resize(chartBox.mapRef);
      }
    });
    return {
      ...toRefs(chartBox)
    };
  }
});