import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_l_frame = _resolveComponent("l-frame");
  const _component_n_message_provider = _resolveComponent("n-message-provider");
  const _component_n_loading_bar_provider = _resolveComponent("n-loading-bar-provider");
  const _component_n_global_style = _resolveComponent("n-global-style");
  const _component_n_config_provider = _resolveComponent("n-config-provider");
  return _openBlock(), _createBlock(_component_n_config_provider, {
    locale: _ctx.zhCN,
    "date-locale": _ctx.dateZhCN,
    "theme-overrides": _ctx.themeOverrides
  }, {
    default: _withCtx(() => [_createVNode(_component_n_loading_bar_provider, null, {
      default: _withCtx(() => [_createVNode(_component_n_message_provider, null, {
        default: _withCtx(() => [_createVNode(_component_l_frame, null, {
          default: _withCtx(() => [_createVNode(_component_router_view)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_n_global_style)]),
    _: 1
  }, 8, ["locale", "date-locale", "theme-overrides"]);
}