import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./asset/css/common.css";
import "./asset/css/colors.css";
import "./asset/css/font/font.css";
import "./asset/css/font/iconfont.css";
import "./asset/css/font/iconfonts.css";
import "./asset/gt4.js";


const app = createApp(App);
app.config.globalProperties.$mc = "8, 54, 138";
app.config.globalProperties.$code = "510183";
app.use(store).use(router).mount("#app");
