import { defineComponent, reactive, shallowRef, onMounted, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
import * as echarts from "echarts";
import "echarts-extension-amap";
import { ckCode } from "@/util/base";
export default defineComponent({
  components: {},
  props: {
    isEchart: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    // 全国
    const mCode = "100000";
    // colors
    const colors = reactive({
      // 区划边框色
      areaBd: "rgba(0, 145, 234, 1)",
      // 棱柱色
      prism: [
      // [
      //   "rgba(41, 62, 237, 1)",
      //   "rgba(0, 103, 255, 1)",
      //   "rgba(0, 136, 255, 1)",
      //   "rgba(0, 138, 208, 1)",
      //   "rgba(0, 186, 215, 1)",
      //   "rgba(0, 173, 135, 1)",
      // ],
      // [
      //   "rgba(176, 0, 0, 1)",
      //   "rgba(196, 0, 0, 1)",
      //   "rgba(215, 26, 0, 1)",
      //   "rgba(251, 0, 0, 1)",
      //   "rgba(255, 123, 0, 1)",
      //   "rgba(255, 171, 0, 1)",
      // ],
      ["rgba(96, 104, 255, 1)", "rgba(146, 89, 251, 1)", "rgba(0, 136, 255, 1)", "rgba(106, 134, 255, 1)", "rgba(188, 71, 227, 1)", "rgba(0, 149, 255, 1)"], ["rgba(252, 94, 39, 1)", "rgba(255, 196, 96, 1)", "rgba(255, 139, 123, 1)", "rgba(251, 204, 0, 1)", "rgba(255, 123, 0, 1)", "rgba(255, 171, 0, 1)"]],
      // 脉冲色
      pulse: [{
        h: "rgba(38, 128, 255, 1)",
        t: "rgba(38, 128, 255, 0.2)"
      }, {
        h: "rgba(253, 174, 73, 1)",
        t: "rgba(253, 174, 73, 0.2)"
      }],
      // 区块色
      polygon: [["rgba(15, 149, 255, 0.4)", "rgba(255, 15, 15, 0.4)"], ["rgba(0, 199, 255, 0.4)", "rgba(255, 15, 15, 0.4)"], ["rgba(0, 32, 254, 0.4)", "rgba(255, 15, 15, 0.4)"],
      // "rgba(0, 255, 195, 0.4)",rgba(255, 116, 18, 0.4)
      ["rgba(95, 0, 255, 0.4)", "rgba(255, 15, 15, 0.4)"], ["rgba(221, 11, 255, 0.4)", "rgba(255, 15, 15, 0.4)"]],
      // 标记色
      labels: {
        park: "rgba(250, 100, 0, 1)",
        build: "rgba(250, 100, 0, 1)",
        score1: "rgba(255, 37, 37, 0.7)",
        score2: "rgba(255, 158, 2, 0.7)",
        score3: "rgba(0, 206, 236, 0.7)"
      }
    });
    // 默认空图标
    const defaultIcon = {
      image: require("@/asset/img/common/placeholder1x1.png"),
      size: [22, 26]
    };
    // 标记图标
    const icons = reactive({
      park: {
        image: require("@/asset/img/icon/park.png"),
        size: [37, 37]
      },
      build: {
        image: require("@/asset/img/icon/build.png"),
        size: [37, 37]
      },
      score1: defaultIcon,
      score2: defaultIcon,
      score3: defaultIcon
    });
    // global
    const amap = reactive({
      ref: shallowRef(),
      chart: shallowRef(),
      AMap: shallowRef(),
      map: shallowRef(),
      loca: shallowRef(),
      dist: shallowRef(),
      labels: shallowRef(),
      // 基本参数
      zoom: 4.66,
      center: [104.623691, 38.3707],
      // 用户所在地城市
      userCode: mCode,
      setUserCode: () => {
        const AMap = amap.AMap;
        const citySearch = new AMap.CitySearch();
        citySearch.getLocalCity((status, result) => {
          if (status === "complete" && result.info === "OK") {
            amap.userCode = result.adcode;
            emit("set-code", result.adcode);
          } else {
            emit("set-code", mCode);
          }
        });
      },
      init: async () => {
        const AMap = amap.AMap;
        let map = undefined;
        const mapOptions = {
          zoom: amap.zoom,
          zooms: [3.6, 20],
          center: amap.center,
          viewMode: "3D",
          showIndoorMap: true,
          rotateEnable: false
          // showLabel: false,
          // zoomEnable: false,
          // mapStyle: "amap://styles/darkblue",
        };

        if (props.isEchart === true) {
          amap.chart = echarts.init(amap.ref);
          amap.chart.setOption({
            amap: mapOptions,
            tooltip: {
              show: true,
              enterable: true,
              trigger: "item"
            },
            series: []
          }, true);
          map = amap.chart.getModel().getComponent("amap").getAMap();
        } else {
          map = new AMap.Map(amap.ref, mapOptions);
        }
        amap.map = map;
        // Loca
        const Loca = await amap.getLoca();
        amap.loca = new Loca.Container({
          map: map
        });
        // // 工具
        // const toolBar = new AMap.ToolBar({
        //   // position: {
        //   //   left: "31px",
        //   //   bottom: "100px",
        //   // },
        //   position: "LB",
        // });
        // map.addControl(toolBar);
        // const controlBar = new AMap.ControlBar({ position: "LB" });
        // map.addControl(controlBar);
      },

      // 显示隐藏
      sh: (sh, elements) => {
        const lyrTypes = lyr.types;
        elements.forEach(e => {
          if (e.lyr !== undefined && lyrTypes[e.lyr]) {
            e.lyr = lyrTypes[e.lyr];
          } else if (e.lyr === "ecFly") {
            ec.ecFlySh(sh, e.type);
          }
        });
        // 显示隐藏层
        const loca = amap.loca;
        loca.layers.forEach(l => {
          const type = l.d$.data && l.d$.data.type;
          const element = elements.filter(e => e.lyr == l.CLASS_NAME && (e.type === undefined || e.type === type));
          if (element.length === 1) {
            if (sh === "show") {
              l.show();
            } else {
              l.hide();
            }
          }
        });
      },
      // 获取amap
      getAMap: async () => {
        return new Promise(resolve => {
          const AMap = setInterval(() => {
            if (window.AMap && amap.ref) {
              clearInterval(AMap);
              resolve(window.AMap);
            }
          }, 100);
        });
      },
      // 获取loca
      getLoca: async () => {
        return new Promise(resolve => {
          const Loca = setInterval(() => {
            if (window.Loca) {
              clearInterval(Loca);
              resolve(window.Loca);
            }
          }, 100);
        });
      },
      // 获取dist
      getDist: async () => {
        return new Promise(resolve => {
          window.AMapUI.loadUI(["geo/DistrictExplorer"], DistrictExplorer => {
            const map = amap.map;
            const districtExplorer = new DistrictExplorer({
              eventSupport: true,
              map: map
            });
            // hover
            districtExplorer.on("featureMouseout featureMouseover", (e, f) => {
              const area = f && f.properties;
              if (area) {
                const type = e.type === "featureMouseover" ? "hover" : "unhover";
                amap.distOp(type, area, e);
              }
            });
            districtExplorer.on("featureMousemove", (e, f) => {
              const area = f && f.properties;
              if (area) {
                amap.distOp("move", area, e);
              }
            });
            // click
            districtExplorer.on("featureClick", (e, f) => {
              const area = f && f.properties;
              if (area) {
                amap.distOp("click", area, e);
              }
            });
            resolve(districtExplorer);
          });
        });
      },
      // dist操作
      distOp: (type, area, e) => {
        const map = amap.map;
        const data = area.extData;
        if (data === undefined || data.name === undefined) {
          return;
        }
        // 鼠标移动操作
        if (type.indexOf("hover") >= 0) {
          // text标记
          const markers = map.getAllOverlays("text");
          let marker;
          for (const m of markers) {
            const ext = m.getExtData();
            if (ext.key === data.type) {
              marker = m;
              break;
            }
          }
          if (marker) {
            if (type === "hover") {
              marker.setText(data.text);
              const lnglat = e.originalEvent ? e.originalEvent.lnglat : null;
              marker.setPosition(lnglat);
              marker.show();
            } else {
              marker.hide();
            }
          }
          // 区域变化
          const dist = amap.dist;
          const polygons = dist.findFeaturePolygonsByAdcode(area.adcode);
          polygons.forEach(p => {
            p.setOptions({
              fillOpacity: type === "hover" ? 0.7 : 0.5
            });
          });
        } else if (type === "move") {
          // text标记
          const markers = map.getAllOverlays("text");
          let marker;
          for (const m of markers) {
            const ext = m.getExtData();
            if (ext.key === data.type) {
              marker = m;
              break;
            }
          }
          if (marker) {
            const lnglat = e.originalEvent ? e.originalEvent.lnglat : null;
            marker.setPosition(lnglat);
          }
        } else if (type === "click") {
          const name = data.name;
          const code = area.adcode.toString();
          const level = area.level;
          // if (level !== "district") {
          //   const param = { name, code };
          //   emit("change-area", param);
          // }
          const param = {
            name,
            code,
            level
          };
          emit("change-area", param);
        }
      },
      // 初始化准备
      ready: async () => {
        return new Promise(resolve => {
          const ready = setInterval(() => {
            if (amap.ref && amap.map && amap.loca) {
              clearInterval(ready);
              resolve();
            }
          }, 100);
        });
      },
      // 加载区域节点
      loadAreaNode: async code => {
        return new Promise(resolve => {
          const dist = amap.dist;
          dist.loadAreaNode(code, (error, areaNode) => {
            if (!error) {
              resolve(areaNode);
            }
          });
        });
      },
      // 行政区颜色
      distColor: (level, prominent = false) => {
        const colorKey = prominent ? 1 : 0;
        const color = level === -1 ? "transparent" : colors.polygon[level][colorKey];
        return color;
      },
      // 复位
      reset: () => {
        // 定位
        loc.locAreas([mCode]);
        // 清除图层
        const pending = [];
        const loca = amap.loca;
        if (loca && loca.layers) {
          loca.layers.forEach(l => {
            pending.push(l);
          });
          pending.forEach(l => {
            l.remove();
          });
        }
        // 清除海量点
        lyr.labelsClear();
        // 清除区块
        lyr.distClear();
      },
      // 清空
      clearMap: () => {
        const map = amap.map;
        map.clearMap();
      }
    });
    // 定位
    const loc = reactive({
      getArea: async (code, sub = 0) => {
        const level = ckCode(code);
        const AMap = amap.AMap;
        const area = await new Promise(resolve => {
          new AMap.DistrictSearch({
            level: level,
            subdistrict: sub,
            extensions: "all"
          }).search(code, (status, result) => {
            resolve(status === "complete" && result.info === "OK" ? result.districtList[0] : status);
          });
        });
        return area;
      },
      locAreas: async codes => {
        await amap.ready();
        const map = amap.map;
        // 清除已有区域
        if (map.getAllOverlays("polygon").length > 0) {
          map.remove(map.getAllOverlays("polygon"));
          if (amap.dist) {
            amap.dist.setAreaNodesForLocating([]);
          }
        }
        if (codes.indexOf(mCode) >= 0) {
          // 包含全国
          map.setZoomAndCenter(amap.zoom, amap.center);
        } else {
          // 获取边界
          const bounds = [];
          for (const code of codes) {
            try {
              const area = await loc.getArea(code);
              area.boundaries.forEach(bound => {
                bounds.push([bound]);
              });
            } catch (error) {}
          }
          const AMap = amap.AMap;
          const polygon = new AMap.Polygon({
            map: map,
            strokeWeight: 1,
            strokeColor: colors.areaBd,
            fillOpacity: 0.02,
            path: bounds
          });
          map.setBounds(polygon.getBounds(), null, null, true);
        }
      },
      locPoint: coord => {
        amap.map.setCenter(coord, false, 100);
      },
      locAuto: () => {
        amap.map.setFitView(amap.labels.getAllOverlays());
      }
    });
    // 标记
    const mkr = reactive({
      mkrText: async key => {
        const AMap = amap.AMap;
        const map = amap.map;
        // 查询标记
        const markers = map.getAllOverlays("text");
        let existed;
        for (const m of markers) {
          const ext = m.getExtData();
          if (ext.key === key) {
            existed = m;
            break;
          }
        }
        // 创建标记
        if (existed === undefined) {
          const marker = new AMap.Text({
            extData: {
              key
            },
            visible: false,
            bubble: true,
            text: "text",
            anchor: key === "Label" ? "bottom-left" : "center",
            cursor: "pointer",
            offset: [0, -20]
          });
          marker.setMap(map);
        }
      }
    });
    // 图层
    const lyr = reactive({
      types: {
        Prism: "Prism",
        Pulse: "PulseLine",
        Label: "Label"
      },
      // 清除
      clear: type => {
        const pending = [];
        const loca = amap.loca;
        loca.layers.forEach(l => {
          if (l.CLASS_NAME === type) {
            pending.push(l);
          }
        });
        pending.forEach(l => {
          l.remove();
        });
      },
      // 海量清除
      labelsClear: () => {
        if (amap.labels) {
          amap.labels.clear();
        }
      },
      // 区块清除
      distClear: () => {
        if (amap.dist) {
          amap.dist.clearFeaturePolygons();
        }
      },
      // 棱柱
      lyrPrism: async areas => {
        const datas = JSON.parse(JSON.stringify(areas));
        const type = "Prism";
        const loca = amap.loca;
        // 清除已有图层
        lyr.clear(type);
        const Loca = await amap.getLoca();
        // 多层
        const count = datas.length;
        datas.forEach((data, idx) => {
          // 创建层
          const layer = new Loca.PrismLayer({
            loca: loca,
            zIndex: 10,
            opacity: 1,
            data: {
              type: data.type,
              label: data.label
            }
          });
          // 获取geo数据
          const geoItems = [];
          data.list.forEach(area => {
            area.count = count;
            area.idx = idx;
            geoItems.push(geo.createItem(type, area));
          });
          const lyrGeo = geo.createGeo(geoItems);
          layer.setSource(lyrGeo);
          // 设置样式
          const layerColor = colors.prism[idx];
          layer.setStyle({
            unit: "meter",
            sideNumber: 4,
            topColor: (_, f) => {
              const value = f.properties.value;
              const level = Math.floor(value / data.duration);
              const color = layerColor[level] ? layerColor[level] : layerColor[layerColor.length - 1];
              return color;
            },
            sideTopColor: (_, f) => {
              const value = f.properties.value;
              const level = Math.floor(value / data.duration);
              const color = layerColor[level] ? layerColor[level] : layerColor[layerColor.length - 1];
              return color;
            },
            sideBottomColor: layerColor[0],
            radius: 15000,
            rotation: 360,
            height: (_, f) => {
              const value = f.properties.value;
              let floor = Math.floor(value * 100 / data.max);
              if (floor === 0) {
                floor = 1;
              } else if (floor > 100) {
                floor = data.max * 2;
                floor = 200;
              }
              const height = floor * 20000;
              return height;
            }
            // label: {
            //   text: {
            //     content: (_: any, f: any) => {
            //       return f.properties.name;
            //     },
            //   },
            // },
            // labelAltitude: 1000,
          });
          // 图层渲染
          layer.show(500);
          // 高度
          layer.addAnimate({
            key: "height",
            easing: "Linear",
            value: [0, 1],
            duration: 500,
            random: true,
            delay: 3000,
            transform: 2000
          });
          // 旋转
          layer.addAnimate({
            key: "rotation",
            easing: "Linear",
            value: [0, 1],
            duration: 500,
            random: true,
            delay: 3000,
            transform: 2000
          });
        });
        // 地图
        const map = amap.map;
        // 3D
        map.setPitch(30, false, 800);
        map.setRotation(0);
        // hover
        map.on("mousemove", e => {
          // text标记
          const markers = map.getAllOverlays("text");
          let marker;
          for (const m of markers) {
            const ext = m.getExtData();
            if (ext.key === type) {
              marker = m;
              break;
            }
          }
          if (marker) {
            let f, label;
            for (const l of loca.layers) {
              if (l.CLASS_NAME === type && l.queryFeature(e.pixel.toArray())) {
                label = l.d$.data && l.d$.data.label;
                f = l.queryFeature(e.pixel.toArray());
                break;
              }
            }
            if (f) {
              marker.setText(`${label}-${f.properties.name}-${f.properties.percent}%`);
              marker.setPosition(e.lnglat);
              marker.show();
            } else {
              marker.hide();
            }
          }
        });
      },
      // 脉冲
      lyrPulse: async areas => {
        const datas = JSON.parse(JSON.stringify(areas));
        const type = "Pulse";
        const loca = amap.loca;
        // 清除已有图层
        lyr.clear(type + "Line");
        const Loca = await amap.getLoca();
        // 多层
        const count = datas.length;
        datas.forEach((data, idx) => {
          // 创建层
          const layer = new Loca.PulseLineLayer({
            loca: loca,
            zIndex: 10,
            opacity: 1,
            data: {
              type: data.type,
              label: data.label
            }
          });
          // 获取geo数据
          const geoItems = [];
          data.list.forEach(area => {
            area.source = data.coord;
            area.count = count;
            area.idx = idx;
            geoItems.push(geo.createItem(type, area));
          });
          const lyrGeo = geo.createGeo(geoItems);
          layer.setSource(lyrGeo);
          // 设置样式
          layer.setStyle({
            altitude: 0,
            lineWidth: 3,
            interval: 0.5,
            duration: 2000,
            speed: 100000 + Math.random() * 50000,
            headColor: colors.pulse[idx].h,
            trailColor: colors.pulse[idx].t
          });
        });
        loca.animate.start();
      },
      // 投点
      lyrLabel: async points => {
        const datas = JSON.parse(JSON.stringify(points));
        const type = "Label";
        const AMap = amap.AMap;
        const map = amap.map;
        // 清除
        lyr.labelsClear();
        // 创建海量层
        if (!amap.labels || props.isEchart === true) {
          const layer = new AMap.LabelsLayer({
            collision: false
          });
          layer.setMap(map);
          amap.labels = layer;
        }
        const labels = amap.labels;
        // 数据
        const labelMarkers = [];
        datas.forEach(data => {
          // text标记
          const markers = map.getAllOverlays("text");
          let marker;
          for (const m of markers) {
            const ext = m.getExtData();
            if (ext.key === type) {
              marker = m;
              break;
            }
          }
          const icon = data.type && icons[data.type] ? icons[data.type] : {};
          if (Object.keys(icon).length > 0) {
            icon.type = "image";
            icon.anchor = "center";
          }
          const textColor = data.type && colors.labels[data.type] ? colors.labels[data.type] : "rgba(102, 102, 102, 1)";
          const label = new AMap.LabelMarker({
            name: data.name,
            position: data.coord,
            zIndex: data.score ? data.score : 0,
            extData: {
              name: data.name
            },
            icon: icon,
            text: {
              content: " " + data.name + " ",
              direction: "right",
              offset: [0, -2],
              style: {
                fontSize: 12,
                fillColor: "rgba(255, 255, 255, 1)",
                backgroundColor: textColor,
                borderColor: textColor,
                borderWidth: 1
              }
            }
          });
          if (marker) {
            label.on("mouseover", e => {
              const position = e.data.data && e.data.data.position;
              const prefix = data.score && data.score > 0 ? `[${data.score}]` : "";
              marker.setText(`${prefix}${data.name}`);
              marker.setPosition(position);
              marker.show();
            });
            label.on("mouseout", () => {
              marker.hide();
            });
            label.on("click", () => {
              emit("click-point", data);
            });
          }
          labelMarkers.push(label);
          if (data.score !== undefined) {
            const score = new AMap.LabelMarker({
              name: data.name,
              position: data.coord,
              zIndex: data.score ? data.score : 0,
              text: {
                content: `${data.score}`,
                direction: "center",
                offset: [1, -2],
                style: {
                  fontSize: 12,
                  fillColor: textColor,
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderColor: textColor,
                  borderWidth: 1
                }
              }
            });
            if (marker) {
              score.on("mouseover", e => {
                const position = e.data.data && e.data.data.position;
                marker.setText(`${data.score}${data.name}`);
                marker.setPosition(position);
                marker.show();
              });
              score.on("mouseout", () => {
                marker.hide();
              });
              score.on("click", () => {
                emit("click-point", data);
              });
            }
            labelMarkers.push(score);
          }
        });
        labels.add(labelMarkers);
      },
      // 区域
      lyrPolygon: async areas => {
        const datas = JSON.parse(JSON.stringify(areas));
        const type = "Polygon";
        if (!amap.dist) {
          const dist = await amap.getDist();
          amap.dist = dist;
        }
        const dist = amap.dist;
        // 清除
        lyr.distClear();
        // 多层
        datas.forEach(async data => {
          // 加载区域
          const areaNode = await amap.loadAreaNode(data.code);
          // 子区域
          if (data.list.length > 0) {
            dist.renderSubFeatures(areaNode, f => {
              const code = f && f.properties.adcode;
              const area = data.list.find(subArea => subArea.code === code.toString());
              f.properties.extData = {
                ...area,
                type: type
              };
              const level = area === undefined ? -1 : Math.floor(area.value / data.duration);
              const color = amap.distColor(level, area === undefined ? false : area.prominent);
              // const color = "red";
              return {
                cursor: "pointer",
                bubble: true,
                fillOpacity: 0.5,
                fillColor: color,
                strokeOpacity: 1,
                strokeWeight: 1,
                strokeColor: color
              };
            });
          }
          dist.setAreaNodesForLocating(areaNode);
        });
      }
    });
    // GEO
    const geo = reactive({
      // 创建item
      createItem: (type, data) => {
        const types = {
          Prism: "Point",
          Pulse: "LineString",
          Label: "Point"
        };
        if (type === "Prism") {
          const coord = {
            ...data.coord
          };
          const deviation = data.idx - (data.count - 1) / 2;
          coord[0] = coord[0] + deviation * 0.3;
          data.coord = coord;
        } else if (type === "Pulse") {
          const coord = {
            ...data.coord
          };
          const deviation = data.idx - (data.count - 1) / 2;
          coord[0] = coord[0] + deviation * 0.3;
          const coords = data.source[0] >= 0 ? [data.source, coord] : [coord, [-1 * data.source[0], -1 * data.source[1]]];
          data.coord = coords;
        }
        const item = {
          type: "Feature",
          geometry: {
            type: types[type],
            coordinates: data.coord
          },
          properties: data
        };
        return item;
      },
      // 创建geo
      createGeo: data => {
        return new window.Loca.GeoJSONSource({
          data: {
            type: "FeatureCollection",
            features: data
          }
        });
      }
    });
    // echart
    const ec = reactive({
      ecFlyColors: {
        eu: "rgba(252, 157, 38, 1)",
        ed: "rgba(125, 140, 255, 1)",
        area: "rgba(255, 30, 0, 1)"
      },
      ecFlyTmpl: {
        lines: {
          coordinateSystem: "amap",
          type: "lines",
          // zlevel: 1,
          effect: {
            show: true,
            period: 4,
            trailLength: 0,
            symbol: "path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z",
            symbolSize: 10
          },
          lineStyle: {
            width: 3,
            opacity: 0.5,
            curveness: 0.3
          },
          tooltip: {
            show: false
          },
          data: []
        },
        point: {
          type: "effectScatter",
          coordinateSystem: "amap",
          // zlevel: 10,
          // symbolSize: 4,
          // rippleEffect: { period: 5, scale: 4, brushType: "fill" },
          // emphasis: { show: true, scale: true, focus: "self" },
          label: {
            show: true,
            formatter: "{b}",
            position: "top",
            color: "rgba(44, 4, 106, 1)"
          },
          itemStyle: {
            color: "rgba(255, 30, 0, 1)"
            //   shadowBlur: 10,
            //   shadowColor: "#333",
          },

          tooltip: {
            textStyle: {
              fontSize: 16,
              color: "#fff"
            },
            backgroundColor: "rgba(0, 2, 89, 0.8)",
            formatter: e => {
              const data = e.data;
              let str = data.name;
              data.values.forEach(v => {
                str += `<br />${v}`;
              });
              return str;
            }
          }
        }
      },
      ecFlySeries: [],
      ecFly: async areas => {
        const datas = JSON.parse(JSON.stringify(areas));
        const serieses = [];
        const linesType = ["eu", "ed"];
        datas.forEach((data, idx) => {
          if (linesType.indexOf(data.type) >= 0) {
            const items = [];
            data.list.forEach(area => {
              const coords = data.coord[0] < 0 ? [area.coord, [data.coord[0] * -1, data.coord[1] * -1]] : [data.coord, area.coord];
              const item = {
                coords: coords,
                lineStyle: {
                  color: ec.ecFlyColors[data.type]
                }
              };
              items.push(item);
            });
            const lines = {
              ...ec.ecFlyTmpl.lines
            };
            lines.name = data.type + idx;
            lines.data = items;
            serieses.push(lines);
          } else {
            const items = [];
            data.list.forEach(area => {
              const item = {
                name: area.name,
                value: area.coord,
                values: area.value
              };
              items.push(item);
            });
            const point = {
              ...ec.ecFlyTmpl.point
            };
            point.name = data.type + idx;
            point.data = items;
            serieses.push(point);
          }
        });
        ec.ecFlySeries = serieses;
        const option = amap.chart.getOption();
        option.series = serieses;
        amap.chart.setOption(option, true);
        amap.map = amap.chart.getModel().getComponent("amap").getAMap();
      },
      ecFlySh: (sh, type) => {
        const serieses = [];
        ec.ecFlySeries.forEach(s => {
          if (sh === "show" && (type === undefined || type === s.name.substring(0, 2) || s.name.substring(0, 4) === "area")) {
            serieses.push(s);
          }
        });
        const option = amap.chart.getOption();
        option.series = serieses;
        amap.chart.setOption(option, true);
        amap.map = amap.chart.getModel().getComponent("amap").getAMap();
      }
    });
    onMounted(async () => {
      amap.AMap = await amap.getAMap();
      amap.setUserCode();
      amap.init();
    });
    return {
      ...toRefs(amap),
      ...toRefs(loc),
      ...toRefs(mkr),
      ...toRefs(lyr),
      ...toRefs(geo),
      ...toRefs(ec)
    };
  }
});