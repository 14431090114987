import { defineComponent, reactive, onMounted, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useMessage, useLoadingBar } from 'naive-ui';
import * as forge from "node-forge";
import { api } from "@/api/";
export default defineComponent({
  components: {},
  props: {
    ind: {
      type: Number,
      default: undefined
    }
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const message = useMessage();
    const loadingBar = useLoadingBar();
    const password = reactive({
      ref: null,
      items: [{
        type: "password",
        name: "old",
        label: "原密码",
        placeholder: "请输入原密码",
        value: "",
        rules: [{
          required: true,
          message: "请输入原密码",
          trigger: ["input", "blur"]
        }]
      }, {
        type: "password",
        name: "new",
        label: "新密码",
        placeholder: "请输入新密码",
        value: "",
        rules: [{
          required: true,
          message: "请输入新密码",
          trigger: ["input", "blur"]
        }]
      }, {
        type: "password",
        name: "rpt",
        label: "重复密码",
        placeholder: "请再次输入新密码",
        value: "",
        rules: [{
          required: true,
          trigger: ["input", "blur"],
          validator: (_, value) => {
            const auth = true;
            if (!value) {
              return new Error("请再次输入新密码");
            } else if (value !== password.models?.new) {
              return new Error("两次密码不一致");
            }
            return auth;
          }
        }]
      }],
      // 绑定数据
      models: {},
      // 验证规则
      rules: {},
      // 按钮
      btns: [{
        label: "取消",
        click: () => {
          emit("close");
        }
      }, {
        type: "primary",
        label: "修改",
        click: () => {
          password.submit();
        }
      }],
      // 初始化
      init: () => {
        password.items.forEach(i => {
          password.models[i.name] = i.value;
          password.rules[i.name] = i.rules;
        });
      },
      // 提交
      submit: () => {
        password.ref.validate(async errors => {
          // 验证通过
          if (!errors) {
            loading.start();
            /** api start */
            // 提取表单数据
            const pwdData = {
              ...password.models
            };
            // 参数设置
            const param = {
              uid: store.getters.user.key,
              password: password.encrypt(pwdData.new),
              oldPassword: password.encrypt(pwdData.old)
            };
            console.log(param);
            // api
            const response = await api("updateUser", param);
            // /** api end */
            loading.end();
            if (response.status === false) {
              message.error(response.data);
            } else {
              // 更新记住密码
              emit("success");
            }
          }
        });
      },
      // 加密
      encrypt: value => {
        const pki = forge.pki;
        const publicKey = pki.publicKeyFromPem(store.getters.pkey);
        const result = forge.util.bytesToHex(publicKey.encrypt(value));
        return result;
      }
    });
    // loading
    const loading = reactive({
      loading: false,
      start: () => {
        loading.loading = true;
        loadingBar.start();
      },
      end: () => {
        loading.loading = false;
        loadingBar.finish();
      }
    });
    onMounted(() => {
      password.init();
    });
    return {
      ...toRefs(password),
      ...toRefs(loading)
    };
  }
});