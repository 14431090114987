import { request, file, baseUrl } from "./config";

const apis: Record<string, any> = {
  /**
   * user
   * 用户管理
   */
  // 新增用户
  addUser: ["p", "ucenter/user/addUser"],
  // 修改用户
  updateUser: ["p", "ucenter/user/updateUser"],
  // 删除用户
  delUser: ["p", "ucenter/user/delUser"],
  // 踢用户下线
  kickOut: ["p", "ucenter/user/kickOut"],
  // 管理员-查询用户列表-分页
  searchUserList: ["p", "ucenter/user/searchUserList"],
  // 通用-获取自身用户信息
  getSelfSampleInfo: ["p", "ucenter/user/getSelfSampleInfo"],
  // 通用-解除微信绑定
  unBindSelfWx: ["p", "ucenter/user/unBindSelfWx"],
  
  // 管理员-解除微信绑定
  adminUnBindWx: ["p", "ucenter/user/adminUnBindWx"],
  // 通用-修改个人用户信息
  updateSelfUser: ["p", "ucenter/user/updateSelfUser"],
  // 各平台用户统计
  cntPlatformUser: ["g", "ucenter/user/cntPlatformUser"],
  /**
   * role
   * 角色
   */
  // 管理员-添加角色
  addRole: ["p", "ucenter/role/addRole"],
  // 管理员-删除角色
  delRole: ["p", "ucenter/role/delRole"],
  // 管理员-更新角色
  updateRole: ["p", "ucenter/role/updateRole"],
  // 管理员-获取角色树状结构
  getAllRoleTree: ["g", "ucenter/role/getAllRoleTree"],
  // 内部调用-获取用户角色列表
  // getUserRoleList: ["p", "ucenter/role/getUserRoleList"],
  // 内部调用-获取用户角色Code列表 
  // getUserRoleCodeList: ["p", "ucenter/role/getUserRoleCodeList"],
  // 管理员-绑定用户角色
  bindUserRole: ["p", "ucenter/role/bindUserRole"],

  
  // 内部调用-获取用户角色id列表 
  // getUserRoleIdList: ["p", "ucenter/role/getUserRoleIdList"],
  

  /**
   * /ucenter/permission/
   * 权限
   */
  // 管理员-添加权限
  addPermission: ["p", "ucenter/permission/addPermission"],
  // 管理员-删除权限
  delPermission: ["p", "ucenter/permission/delPermission"],
  // 管理员-更新权限
  updatePermission: ["p", "ucenter/permission/updatePermission"],
  // 管理员-获取权限树状结构
  getAllPermissionTree: ["g", "ucenter/permission/getAllPermissionTree"],
  // 内部调用-获取用户权限
  // getUserPermissionList: ["p", "ucenter/permission/getUserPermissionList"],
  // 内部调用-获取用户权限code
  // getUserPermissionCodeList: ["p", "ucenter/permission/getUserPermissionCodeList"],
  // 内部调用-获取角色对应权限code
  // getRolePermissionCodeList: ["p", "ucenter/permission/getRolePermissionCodeList"],
  // 管理员-角色与权限绑定
  bindRolePermission: ["p", "ucenter/permission/bindRolePermission"],
  // 通用-刷新redis权限列表
  refreshPermissionRedis: ["g", "ucenter/permission/refreshPermissionRedis"],
  // 内部调用-获取用户权限Id
  // getUserPermissionIdList: ["p", "ucenter/permission/getUserPermissionIdList"],
  // 内部调用-获取角色对应权限Id
  getRolePermissionIdList: ["p", "ucenter/permission/getRolePermissionIdList"],
  // /ucenter/ucenter/permission/getRolePermissionIdList
  
  /**
   * dept
   * 部门
   */
  // 管理员-添加部门
  addDept: ["p", "ucenter/dept/addDept"],
  // 管理员-删除部门
  delDept: ["p", "ucenter/dept/delDept"],
  // 管理员-更新部门
  udpateDept: ["p", "ucenter/dept/updateDept"],
  // 管理员-获取部门树状结构
  getDeptTree: ["g", "ucenter/dept/getDeptTree"],
  // 内部调用-获取用户部门列表
  // getUserDeptList: ["p", "ucenter/dept/getUserDeptList"],
  // 内部调用-获取用户部门Code列表 
  // getUserDeptCodeList: ["p", "ucenter/dept/getUserDeptCodeList"],
  // 管理员-用户绑定部门
  userBindDept: ["p", "ucenter/dept/userBindDept"],
  
  /**
   * menu
   * 菜单
   */
  // 管理员-添加菜单
  addMenu: ["p", "ucenter/menu/addMenu"],
  // 管理员-删除菜单
  delMenu: ["p", "ucenter/menu/delMenu"],
  // 管理员-更新菜单
  updateMenu: ["p", "ucenter/menu/updateMenu"],
  // 管理员-获取完整菜单树
  getMenuTree: ["g", "ucenter/menu/getMenuTree"],
  // 内部调用-获取用户菜单列表
  // getUserMenuList: ["p", "ucenter/menu/getUserMenuList"],
  // 内部调用-获取用户菜单Code列表 
  // getUserMenuCodeList: ["p", "ucenter/menu/getUserMenuCodeList"],
  // 管理员-用户绑定菜单
  userBindMenu: ["p", "ucenter/menu/userBindMenu"],
  
  /**
   * platform
   * 平台
   */
  // 管理员-添加平台
  addPlatform: ["p", "ucenter/platform/addPlatform"],
  // 管理员-删除平台
  delPlatform: ["p", "ucenter/platform/delPlatform"],
  // 管理员-更新平台
  updatePlatform: ["p", "ucenter/platform/updatePlatform"],
  // 管理员-获取完整平台树
  getPlatformTree: ["g", "ucenter/platform/getPlatformTree"],
  // 内部调用-获取用户平台列表
  // getUserPlatformList: ["p", "ucenter/platform/getUserPlatformList"],
  // 内部调用-获取用户平台Code列表 
  // getUserPlatformCodeList: ["p", "ucenter/platform/getUserPlatformCodeList"],
  // 管理员-用户绑定平台
  userBindPlatform: ["p", "ucenter/platform/userBindPlatform"],
  
  /**
   * post
   * 岗位
   */
  // 管理员-添加岗位
  addPost: ["p", "ucenter/post/addPost"],
  // 管理员-删除岗位
  delPost: ["p", "ucenter/post/delPost"],
  // 管理员-更新岗位
  updatePost: ["p", "ucenter/post/updatePost"],
  // 内部调用-获取岗位列表
  // getUserPostList: ["p", "ucenter/post/getUserPostList"],
  // 内部调用-获取用户岗位列表-分页
  searchPostPage: ["p", "ucenter/post/searchPostPage"],
  // 内部调用-获取用户岗位Code列表 
  // getUserPostCodeList: ["p", "ucenter/post/getUserPostCodeList"],
  // 管理员-用户绑定岗位
  userBindPost: ["p", "ucenter/post/userBindPost"],
  // 管理员-获取所有岗位列表
  getAllPost: ["g", "ucenter/post/getAllPost"],
  
  /**
   * score
   * 分数
   */
  // 管理员-添加分数
  adminAddScore: ["p", "ucenter/score/adminAddScore"],
  // 管理员-扣分操作
  adminSubScore: ["p", "ucenter/score/adminSubScore"],
  // 内部调用-调用扣分接口时进行扣分
  // subScore: ["p", "ucenter/score/subScore"],
  // 管理员-根据条件查询分数记录-分页
  searchScoreLogPage: ["p", "ucenter/score/searchScoreLogPage"],
  // 通用接口-用户查询自身分数记录-分页
  searchSelfScoreLogPage: ["p", "ucenter/score/searchSelfScoreLogPage"],
  // 管理员-获取一定时间内的整体积分变动情况
  sumScoreLog: ["p", "ucenter/score/sumScoreLog"],
  adminModifyScore: ["p", "ucenter/score/adminModifyScore"],
  // 用户一定时间内扣分排名 /ucenter/score/userSubScoreRank
  userSubScoreRank: ["p", "ucenter/score/userSubScoreRank"],
 
  // 列表-最近充值记录 /ucenter/score/rechargeRecord
  rechargeRecord: ["p", "ucenter/score/rechargeRecord"],

  // 管理员-批量生成用户账户
  adminBatchRegisterUsers: ["p", "ucenter/user/adminBatchRegisterUsers"],

  // 管理员-批量修改用户
  adminBatchUpdateUsers: ["p", "ucenter/user/adminBatchUpdateUsers"],

  

  /**
   * logging
   * 
   */

  // 统计一定时间内登录次数 /logging/operation/cntLogin
  cntLogin: ["p", "logging/operation/cntLogin"],

  // 7天内登录统计折线图 /logging/operation/cntDaysLogin
  cntLocntDaysLogingin: ["p", "logging/operation/cntDaysLogin"],

  // 近期公司搜索排名-公共 /logging/operation/cntSearchCompany
  cntSearchCompany: ["p", "logging/operation/cntSearchCompany"],

  // 一定时间内 根据用户统计操作次数 /logging/operation/cntUserOperNum
  cntUserOperNum: ["p", "logging/operation/cntUserOperNum"],

  // 后台-登录记录列表 /logging/operation/getLoginList
  getLoginList: ["p", "logging/operation/getLoginList"],
  // 个人登录记录查询-分页-个人 /logging/operation/getSelfLoginList
  getSelfLoginList: ["p", "logging/operation/getSelfLoginList"],

  
  // 根据条件检索日志信息
  logPage: ["p", "ucenter/operation/logPage"],

  getUserSearchHistory: ["p", "logging/operation/getUserSearchHistory"],

  // 分页查询更新日志
  queryLog: ["p", "logging/updateLog/queryLog"],
  // 管理员-删除更新日志
  deleteLog: ["p", "logging/updateLog/deleteLog"],
  // 管理员-添加更新日志
  saveLog: ["p", "logging/updateLog/saveLog"],
  
  // 根据日期聚合地区登录数量
  getLoginCnt4Map: ["p", "logging/operation/getLoginCnt4Map"],
  // 根据日期分页查询地区登录列表信息
  getLoginAreaPage: ["p", "logging/operation/getLoginAreaPage"],
  

  
  companyReportExportCount: ["p", "zongheng/record/companyReportExportCount"],
  

  getUserLoginList: ["p", "ucenter/user/getUserLoginList"],
  
  // 操作记录
  // queryCompanyInfoTSecondLog: ["g", "ucenter/operatelog/queryCompanyInfoTSecondLog"],

  
  // 检测当前账号是否已经登录
  isLogin: ["g", "sso/auth/isLogin"],

  // 导出现有系统的权限列表
  permissionExport: ["g", "ucenter/permission/permissionExport"],

  // 导入权限信息
  permissionImport: ["p", "ucenter/permission/permissionImport"],
  

  // 管理员-批量导出用户查询记录
  // exportBatchUsersSearchLog: "logging/operation/exportBatchUsersSearchLog",
  
  exportBatchUsersSearchLog: "logging/operation/exportBatchUsersSearchLog", // 下载报告
};

const apiError = {
  status: false,
  data: "API NOT FOUND",
};

const prjRoot = "/";

export async function api(key: string, param = {}) {
  const item = apis[key];
  if (item === undefined) {
    return apiError;
  } else {
    return request(item[0], prjRoot + item[1], param);
  }
}

export async function download(key: string, label: string, data = {}) {
  const item = apis[key];
  if (item === undefined) {
    return {
      status: false,
      data: "API NOT FOUND",
    };
  } else {
    return file(item, label, data);
  }
}

export function rootUrl() {
  return Promise.resolve(baseUrl());
}

export function apiUrl(key: string) {
  return prjRoot + apis[key];
}
