import { defineComponent, reactive, computed, toRefs } from 'vue';
import tRegion from "../tool/region.vue";
import tIndustry from "../tool/industry.vue";
import { getAreaName } from "@/util/area";
import { getIndHName } from "@/util/industry";
export default defineComponent({
  components: {
    tRegion,
    tIndustry
  },
  props: {
    s: {
      type: String,
      default: ""
    },
    t: {
      type: String,
      default: ""
    },
    ih: {
      type: Number,
      default: undefined
    },
    data: {
      type: Object,
      default: {}
    }
  },
  setup(props, {
    emit
  }) {
    const compare = reactive({
      rs: {
        tip: "起始",
        label: computed(() => {
          return props.s ? getAreaName(props.s) : "请选择";
        })
      },
      rt: {
        tip: "目标",
        label: computed(() => {
          return props.t ? getAreaName(props.t) : "请选择";
        })
      },
      indh: {
        label: computed(() => {
          return props.ih !== undefined ? getIndHName(props.ih) : "请选择";
        })
      },
      setCode: (r, code) => {
        const codeData = {
          key: r,
          value: code
        };
        emit("setCode", codeData);
      },
      setIndh: ih => {
        emit("setIndh", ih);
      },
      search: () => {
        emit("search");
      },
      // mask
      mask: false,
      maskShow: () => {
        compare.mask = true;
      },
      maskHide: () => {
        compare.mask = false;
      }
    });
    return {
      ...toRefs(compare)
    };
  }
});