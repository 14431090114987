import { defineComponent, reactive, toRefs } from 'vue';
export default defineComponent({
  components: {},
  setup() {
    const dialog = reactive({
      show: false,
      options: {},
      icon: "",
      icons: {
        success: require("@/asset/img/icon/dialog-success.png"),
        warning: require("@/asset/img/icon/dialog-warning.png"),
        error: require("@/asset/img/icon/dialog-error.png")
      },
      // 渲染
      render: options => {
        if (options.btns === undefined) {
          options.btns = [{
            label: "确 定",
            type: "primary"
          }];
        }
        dialog.options = options;
        dialog.show = true;
      },
      // 点击
      click: btn => {
        btn.click ? btn.click() : dialog.close();
      },
      // 关闭
      close: () => {
        dialog.options = {};
        dialog.show = false;
      }
    });
    return {
      ...toRefs(dialog)
    };
  }
});