import { defineComponent, reactive, watch, onMounted, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
import { api } from "@/api/";
export default defineComponent({
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const search = reactive({
      /**
       * 基本参数
       * @param {string} name 关键词
       */
      name: "",
      /**
       * 输入控件
       */
      inputRef: null,
      placeholder: "请输入公司名称等",
      allowBlur: false,
      focus: () => {
        search.allowBlur = true;
        search.open();
      },
      blur: () => {
        if (search.allowBlur) {
          search.close();
        }
      },
      /**
       * 关联控件
       */
      associated: false,
      // 类型[历史|查询]
      associatedType: "",
      // 关联数据
      associatedDatas: [],
      // 关联历史
      associatedHistory: [],
      getHistory: () => {
        const history = [
          // { value: "1", label: "企业名称企业名称企业名称1", abbr: "企业1" },
        ];
        search.associatedHistory = history;
      },
      // 关联查询
      associatedMatched: [{
        value: "123"
      }],
      getMatched: key => {
        const exist = search.associatedMatched.findIndex(i => i.value === key);
        // 输入清空
        if (key === "") {
          timer.clear();
          search.associatedDatas = [];
        }
        // 已存数据完全匹配
        else if (exist >= 0) {
          timer.clear();
          search.associatedDatas = search.associatedMatched;
          // if (search.associatedDatas.length > 0 && search.searching === false) {
          //   search.associated = true;
          // }
        }
        // 模糊查询
        else {
          timer.set(() => {
            search.getList(key);
          }, 600);
        }
      },
      getList: async key => {
        const response = await api("fuzzyCompanyName", {
          keyword: key
        });
        if (response.status === true) {
          const datas = [];
          response.data.forEach(i => {
            const name = i.companyName.replace(/<\/?.+?\/?>/g, "");
            datas.push({
              value: name,
              label: i.companyName,
              abbr: name.slice(0, 3),
              delete: false
            });
          });
          search.associatedMatched = datas;
          search.associatedDatas = search.associatedMatched;
          // if (datas.length > 0 && search.searching === false) {
          //   search.associated = true;
          // }
        }
      },

      open: () => {
        search.searching = false;
        search.associated = true;
        search.associatedDatas = [];
        if (search.name === "") {
          // 历史记录
          search.associatedType = "history";
          search.associatedDatas = search.associatedHistory;
          // if (search.associatedDatas.length > 0 && search.searching === false) {
          //   search.associated = true;
          // }
        } else {
          // 模糊匹配
          search.associatedType = "matched";
          const key = search.name;
          search.getMatched(key);
        }
      },
      close: () => {
        search.associated = false;
      },
      mousedown: () => {
        search.allowBlur = false;
      },
      mouseup: () => {
        search.allowBlur = true;
      },
      // error
      error: false,
      errorMsg: "",
      check: () => {
        const checked = search.name !== "";
        search.errorMsg = "请输入关键词";
        if (search.error === true && checked) {
          search.error = false;
        }
        return checked;
      },
      // search
      searching: false,
      search: () => {
        if (props.loading === false) {
          const checked = search.check();
          if (checked) {
            // search.close();
            search.inputRef.blur();
            search.searching = true;
            emit("search", search.name);
          } else {
            search.error = true;
          }
        }
      },
      // select
      select: name => {
        // search.close();
        search.inputRef.blur();
        emit("target", name);
      }
    });
    const timer = reactive({
      timer: undefined,
      set: (callback, ms) => {
        timer.clear();
        timer.timer = setTimeout(callback, ms);
      },
      clear: () => {
        clearTimeout(timer.timer);
      }
    });
    watch(() => search.name, () => {
      search.open();
      search.check();
    });
    onMounted(() => {
      search.getHistory();
    });
    return {
      ...toRefs(search)
    };
  }
});