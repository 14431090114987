import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "lb-editor"
};
const _hoisted_2 = {
  class: "lb-editor-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_editor = _resolveComponent("editor", true);
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_editor, {
    "tag-name": "div",
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.model = $event),
    "tinymce-script-src": _ctx.src,
    init: _ctx.init
  }, null, 8, ["modelValue", "tinymce-script-src", "init"])])]);
}