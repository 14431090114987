import { defineComponent } from 'vue';
export default defineComponent({
  components: {},
  props: {
    name: {
      type: String,
      default: ""
    }
  },
  setup() {
    return {};
  }
});