import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "g6-graph"
};
const _hoisted_2 = {
  key: 0,
  class: "g6-graph-box",
  ref: "ref"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.ready ? (_openBlock(), _createElementBlock("div", _hoisted_2, null, 512)) : _createCommentVNode("", true)]);
}