/**
 * 验证ck类型type
 * @param {any} value 待判断数据
 * @return {string} type 类型
 */
export function ckType(value: any): string {
  const types: Record<string, string> = {
    "[object Object]": "object",
    "[object Array]": "array",
    "[object String]": "string",
    "[object Number]": "number",
  };
  const objectType = Object.prototype.toString.call(value);
  const type = types[objectType];
  return type;
}
/**
 * 验证ck编码code
 * @param {any} code 待判断编码
 * @return {boolean | string} 结果
 */
export function ckCode(code: string): boolean | string {
  let ck: boolean | string = true;
  do {
    // 数字
    if (parseInt(code).toString() !== code) {
      ck = false;
      break;
    }
    // 位数
    if (code.length !== 6) {
      ck = false;
      break;
    }
    // 国
    if (code === "100000") {
      ck = "country";
      break;
    }
    // 省
    if (Math.floor(parseInt(code) / 1000) * 1000 === parseInt(code)) {
      ck = "province";
      break;
    }
    // 市
    if (Math.floor(parseInt(code) / 10) * 10 === parseInt(code)) {
      ck = "city";
      break;
    }
    // 区
    ck = "district";
  } while (false);
  return ck;
}
