import { defineComponent, reactive, computed, toRefs } from 'vue';
import tRegion from "../tool/region.vue";
import tIndustry from "../tool/industry.vue";
import { getAreaName } from "@/util/area";
import { getIndHName } from "@/util/industry";
export default defineComponent({
  components: {
    tRegion,
    tIndustry
  },
  props: {
    t: {
      type: String,
      default: ""
    },
    ih: {
      type: Number,
      default: undefined
    },
    data: {
      type: Object,
      default: {}
    }
  },
  setup(props, {
    emit
  }) {
    const stats = reactive({
      /**
       * 按钮样式
       */
      btnColor: "#ffa539",
      btnStyle: {
        minWidth: "100px"
      },
      /**
       * 参数项
       */
      rt: {
        tip: "目标",
        label: computed(() => {
          return props.t ? getAreaName(props.t) : "请选择";
        })
      },
      indh: {
        label: computed(() => {
          return props.ih !== undefined ? getIndHName(props.ih) : "请选择";
        })
      },
      /**
       * 参数设置
       */
      setCode: (r, code) => {
        const codeData = {
          key: r,
          value: code
        };
        emit("setCode", codeData);
      },
      setIndh: ih => {
        emit("setIndh", ih);
      },
      /**
       * 比例
       */
      percents: [{
        icon: "tech",
        label: "优质企业[75-100]",
        value: computed(() => props.data.pQ),
        color: "0, 185, 92"
      }, {
        icon: "tech",
        label: "潜力企业[70-75)",
        value: computed(() => props.data.pO),
        color: "0, 45, 197"
      }, {
        icon: "tech",
        label: "成长企业[0-70)",
        value: computed(() => props.data.pR),
        color: "179, 62, 18"
      }],
      /**
       * 操作
       */
      search: () => {
        emit("search");
      },
      getList: () => {
        emit("get-l");
      },
      showAs: () => {
        emit("show-as");
      }
    });
    return {
      ...toRefs(stats)
    };
  }
});