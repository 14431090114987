import { defineComponent, reactive, computed, shallowRef, nextTick, watch, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
import * as echarts from "echarts";
export default defineComponent({
  components: {},
  props: {
    maxHeight: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: {}
    }
  },
  setup(props, {
    emit
  }) {
    const tool = reactive({
      hTool: 20,
      hMax: 0,
      setHMax: () => {
        tool.hMax = props.maxHeight;
      },
      unit: computed(() => {
        return props.data.unit;
      }),
      lg: (direction, colors) => {
        const k1 = direction === "rl" ? 1 : 0;
        const k2 = direction === "bt" ? 1 : 0;
        const k3 = direction === "lr" ? 1 : 0;
        const k4 = direction === "tb" ? 1 : 0;
        const interval = colors.length - 1 > 0 ? 1 / (colors.length - 1) : 0;
        const colorBox = [];
        colors.forEach((c, idx) => {
          colorBox.push({
            offset: c[1] ? c[1] : idx * interval,
            color: c[0]
          });
        });
        return new echarts.graphic.LinearGradient(k1, k2, k3, k4, colorBox);
      },
      animatedTime: 5000
    });
    const chart = reactive({
      ready: computed(() => {
        chart.draw();
        return Object.keys(props.data).length > 0;
      }),
      enter: true,
      height: 0,
      iHeight: 60,
      // 对象
      ref: null,
      // 实例
      chart: shallowRef(),
      // 配置
      opts: {
        // 动态工具
        timeline: {
          show: false,
          top: 0,
          data: []
        },
        // tooltip
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          backgroundColor: "rgba(17, 95, 182, 0.5)",
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          },
          formatter: `{b}<br/>{a}: {c}${tool.unit}`
        },
        legend: {
          data: [],
          left: "center"
        },
        grid: [
        // 左
        {
          show: false,
          left: 0,
          top: 40,
          bottom: 0,
          width: "50%",
          containLabel: true
        },
        // 右
        {
          show: false,
          right: 0,
          top: 40,
          bottom: 0,
          width: "50%",
          containLabel: true
        },
        // 中
        {
          show: false,
          left: "50%",
          top: 40,
          bottom: 0,
          width: 0,
          containLabel: true
        }],
        xAxis: [],
        yAxis: [],
        series: []
      },
      // 画
      draw: () => {
        nextTick(() => {
          chart.monitor();
        });
      },
      // 数据处理
      process: () => {
        const datas = props.data.datas;
        const labelList = datas[datas.length - 1].list;
        // 行
        const maxRows = Math.floor((props.maxHeight - tool.hTool) / chart.iHeight);
        const rows = Math.min(labelList.length, maxRows);
        // 高度
        const height = rows * chart.iHeight + tool.hTool;
        chart.height = height;
        emit("set-h", height, chart.enter);
        // X轴
        const xAxis = [];
        // Y轴
        const yAxis = [];
        // 图例
        const legend = [];
        // 数据
        const series = [];
        /**
         * 核心数据
         */
        datas.forEach((c, idx) => {
          const yDatas = [];
          const sDatas = [];
          c.list.forEach((i, cidx) => {
            if (cidx < rows) {
              yDatas[cidx] = i.name;
              sDatas[cidx] = i.value !== undefined ? i.value : 0;
            }
          });
          // X
          const x = idx == datas.length - 1 ? {
            gridIndex: idx,
            show: false,
            axisLabel: {
              show: false
            }
          } : {
            gridIndex: idx,
            type: "value",
            inverse: (idx & 1) === 0,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            }
          };
          xAxis.push(x);
          // Y
          const y = {
            gridIndex: idx,
            type: "category",
            inverse: true,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            data: yDatas
          };
          yAxis.push(y);
          // legend
          if (c.label) {
            legend.push(c.label);
          }
          // series
          series.push(chart.setS(c, sDatas, idx));
        });
        chart.opts.xAxis = xAxis;
        chart.opts.yAxis = yAxis;
        chart.opts.legend.data = legend;
        chart.opts.series = series;
      },
      // 设置series
      setS: (c, datas, idx) => {
        const seriesBase = {
          name: c.label,
          type: "bar",
          barWidth: 20,
          xAxisIndex: idx,
          yAxisIndex: idx,
          data: datas,
          showBackground: true
        };
        const series = {
          ...seriesBase
        };
        if (idx === 0) {
          series.backgroundStyle = {
            color: "rgba(123, 138, 252, 0.15)"
          };
          series.itemStyle = {
            color: tool.lg("tb", [["rgba(123, 138, 252, 0.8)"], ["rgba(123, 138, 252, 1)"]])
          };
          series.label = {
            show: true,
            // formatter: `{c}${tool.unit}`,
            formatter: i => {
              return i.data > 0 ? `${i.data}${tool.unit}` : "";
            },
            position: "insideRight",
            offset: [0, 2],
            fontSize: 16,
            color: "#000"
          };
        } else if (idx === 1) {
          series.backgroundStyle = {
            color: "rgba(255, 157, 13, 0.15)"
          };
          series.itemStyle = {
            color: tool.lg("tb", [["rgba(255, 157, 13, 0.8)"], ["rgba(255, 157, 13, 1)"]])
          };
          series.label = {
            show: true,
            formatter: i => {
              return i.data > 0 ? `${i.data}${tool.unit}` : "";
            },
            position: "insideLeft",
            offset: [0, 2],
            fontSize: 16,
            color: "rgba(23, 70, 255, 1)"
          };
        } else {
          series.label = {
            show: true,
            formatter: "{b}",
            position: "top",
            offset: [0, 2],
            fontSize: 16,
            color: "#000"
            // backgroundColor: "#f00",
          };
        }

        return series;
      },
      // 渲染
      render: () => {
        // 已渲染
        if (chart.ref && chart.chart) {
          chart.chart.setOption(chart.opts, true);
          chart.rerender();
        } else {
          chart.chart = echarts.init(chart.ref);
          setTimeout(() => {
            chart.chart.setOption(chart.opts);
          }, 800);
          // window resize
          window.addEventListener("resize", () => {
            chart.rerender();
          });
          // finish
          chart.chart.on("finished", () => {
            // finished
          });
          // click
          chart.chart.on("click", e => {
            const datas = props.data.datas;
            const s = e.seriesIndex;
            const i = e.dataIndex;
            if (datas[s] && datas[s].list[i]) {
              const param = {
                label: datas[s].label,
                type: datas[s].type,
                name: datas[s].list[i].name,
                code: datas[s].list[i].data.code.toString()
              };
              emit("get-i", param);
            }
          });
        }
      },
      // 重新渲染
      rerender: () => {
        chart.chart.resize(chart.ref);
      },
      // 销毁
      destroy: () => {
        chart.chart.dispose();
      },
      // 监听元素尺寸
      monitor: () => {
        new ResizeObserver(() => {
          if (chart.ref) {
            const height = chart.ref.offsetHeight;
            // 进入操作结束
            if (chart.enter === true && chart.height !== height) {
              chart.enter = false;
            }
            // 进入||高度变化
            if (chart.enter === true || chart.height !== height) {
              chart.process();
              setTimeout(() => {
                if (chart.ref) {
                  chart.render();
                }
              }, 500);
            }
            // 高度无变化
            else {
              chart.rerender();
            }
            chart.animate();
          }
        }).observe(chart.ref);
      },
      // 动态
      animated: null,
      animate: () => {
        // 清除
        if (chart.animated) {
          clearInterval(chart.animated);
        }
        let s = 0;
        let i = 0;
        const series = chart.opts.series;
        chart.animated = setInterval(() => {
          if (chart.ref) {
            chart.chart.dispatchAction({
              type: "showTip",
              seriesIndex: s,
              dataIndex: i
            });
            if (series[s].data[i + 1] === undefined) {
              i = 0;
              if (s + 2 === series.length || !series[s + 1]) {
                s = 0;
              } else {
                s++;
              }
            } else {
              i++;
            }
          }
        }, tool.animatedTime);
      }
    });
    watch(() => props.maxHeight, () => {
      chart.process();
    });
    return {
      ...toRefs(chart)
    };
  }
});