import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { reactive, onMounted, watch, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
import { h, defineComponent, ref } from 'vue';
import screenfull from "screenfull";
import lHeader from "./header.vue";
import lMenu from "./menu.vue";
import { RouterLink } from 'vue-router';
import { NIcon } from 'naive-ui';
function renderIcon(icon) {
  return () => h(NIcon, null, {
    default: () => h(icon)
  });
}
export default defineComponent({
  components: {
    lHeader,
    lMenu
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const menuInstRef = ref(null);
    const tool = reactive({
      icon: (icon, size = undefined) => {
        const iconP = size === undefined ? null : {
          size: size
        };
        const style = size === undefined ? {} : {
          fontSize: `${size}px`
        };
        return () => h(NIcon, iconP, {
          default: () => h("i", {
            class: `iconfonts icon-${icon}`,
            style: style
          })
        });
      }
    });
    const frame = reactive({
      mainRef: null,
      isFull: false,
      // inverted: ref(false),
      inverted: ref(true),
      selectedKey: '',
      hData: {
        title: '',
        name: ''
      },
      menuOptions: [{
        label: () => h(RouterLink, {
          to: {
            name: 'home'
          }
        }, {
          default: () => '首页'
        }),
        key: 'home',
        // icon: renderIcon(HomeIcon),
        icon: tool.icon("home1")
        // icon: () => h(NIcon, null, { default: () => h(iconComponent) })
      }, {
        label: '系统管理',
        key: 'users',
        // icon: renderIcon(DesktopSharp),
        icon: tool.icon("diannao-shuju-fill"),
        // disabled: true,
        children: [{
          label: () => h(RouterLink, {
            to: {
              name: 'user'
            }
          }, {
            default: () => '用户管理'
          }),
          key: 'user'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'power'
            }
          }, {
            default: () => '角色管理'
          }),
          key: 'power'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'permission'
            }
          }, {
            default: () => '权限管理'
          }),
          key: 'permission'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'dept'
            }
          }, {
            default: () => '部门管理'
          }),
          key: 'dept'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'menu'
            }
          }, {
            default: () => '菜单管理'
          }),
          key: 'menu'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'platform'
            }
          }, {
            default: () => '平台管理'
          }),
          key: 'platform'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'post'
            }
          }, {
            default: () => '岗位管理'
          }),
          key: 'post'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'record'
            }
          }, {
            default: () => '更新日志'
          }),
          key: 'record'
          // icon: renderIcon(HomeIcon)
        }]
      }, {
        label: '操作管理',
        key: 'log',
        // icon: renderIcon(FlowerSharp),
        icon: tool.icon("flower"),
        children: [{
          label: () => h(RouterLink, {
            to: {
              name: 'userOnle'
            }
          }, {
            default: () => '在线用户'
          }),
          key: 'userOnle'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'logList'
            }
          }, {
            default: () => '登录记录'
          }),
          key: 'logList'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'logNum'
            }
          }, {
            default: () => '登录次数'
          }),
          key: 'logNum'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'apiNum'
            }
          }, {
            default: () => '接口操作次数'
          }),
          key: 'apiNum'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'pdfList'
            }
          }, {
            default: () => '导出报告数量统计'
          }),
          key: 'pdfList'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'searchList'
            }
          }, {
            default: () => '最近搜索公司列表'
          }),
          key: 'searchList'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'searchCompany'
            }
          }, {
            default: () => '企业搜索统计'
          }),
          key: 'searchCompany'
          // icon: renderIcon(HomeIcon)
        }]
      }, {
        label: '分数管理',
        key: 'score',
        // icon: renderIcon(BarChart),
        icon: tool.icon("bar-chart"),
        children: [{
          label: () => h(RouterLink, {
            to: {
              name: 'score'
            }
          }, {
            default: () => '分数记录'
          }),
          key: 'score'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'recharge'
            }
          }, {
            default: () => '充值记录'
          }),
          key: 'recharge'
          // icon: renderIcon(HomeIcon)
        }, {
          label: () => h(RouterLink, {
            to: {
              name: 'consumption'
            }
          }, {
            default: () => '消费排名'
          }),
          key: 'consumption'
          // icon: renderIcon(HomeIcon)
        }]
      }],

      full: () => {
        if (screenfull.isEnabled) {
          frame.isFull = true;
          screenfull.toggle();
        }
      },
      exit: () => {
        screenfull.exit();
      },
      handleClick: () => e => {
        let name = e.key;
        console.log(name);
        if (name !== route.name) {
          router.push({
            name: name
          });
        }
      }
    });
    onMounted(() => {
      setTimeout(() => {
        const query = route;
        const key = route.name;
        frame.selectedKey = route.name;
        menuInstRef.value?.showOption(key);
        frame.hData.name = route.meta.title;
        // console.log(route.meta.title)
      }, 500);
      screenfull.on("change", () => {
        frame.isFull = screenfull.isFullscreen;
      });
    });
    watch(() => route.name, () => {
      frame.mainRef?.scrollTo({
        top: 0
      });
    });
    return {
      screenfull,
      store,
      menuInstRef,
      ...toRefs(frame),
      ...toRefs(tool)
    };
  }
});