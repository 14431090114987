import { defineComponent, getCurrentInstance, h, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import "core-js/modules/es.array.push.js";
import { NIcon } from "naive-ui";
import bClock from "@/component/base/clock.vue";
import uDialog from "@/component/usual/dialog.vue";
import tPassword from "@/component/peculiar/tool/password.vue";
export default defineComponent({
  components: {
    bClock,
    uDialog,
    tPassword
  },
  props: {
    isFull: {
      type: Boolean,
      default: false
    },
    hData: {
      type: Object,
      default: false
    }
  },
  emits: ["full"],
  setup(props, {
    emit
  }) {
    const store = useStore();
    const router = useRouter();
    // 相关
    const user = store.getters.user;
    const username = user.username;
    const mc = getCurrentInstance()?.appContext.config.globalProperties.$mc;
    const renderIcon = icon => {
      return () => h(NIcon, null, {
        default: () => h("i", {
          class: `if i-${icon}`
        })
      });
    };
    // base
    const base = reactive({
      title: "纵横大数据-用户管理平台",
      toHome: () => {
        router.push({
          name: "home"
        });
      }
    });
    // dialog
    const dialog = reactive({
      dRef: null
    });
    // 按钮组
    const btns = reactive({
      // 全屏按钮
      fCarrier: {
        icon: "fullscreen",
        iconExit: "fullscreen-exit",
        color: `rgba(${mc}, 1)`,
        click: () => {
          emit("full");
        }
      },
      // 用户按钮载体
      uCarrier: {
        label: username.substring(0, 1).toUpperCase(),
        icon: "user",
        color: `rgba(${mc}, 0.7)`
      },
      // 用户按钮组
      uGroup: [{
        key: "report",
        label: "个人信息",
        icon: renderIcon("user"),
        props: {
          onClick: () => {
            router.push({
              name: "report"
            });
          }
        }
      }, {
        key: "password",
        label: "修改密码",
        icon: renderIcon("password"),
        props: {
          onClick: () => {
            password.pwdShow = true;
          }
        }
      }, {
        key: "logout",
        label: "退出登录",
        icon: renderIcon("logout"),
        props: {
          onClick: () => {
            dialog.dRef.render({
              icon: "warning",
              title: "退出登录？",
              btns: [{
                label: "取消"
              }, {
                type: "primary",
                label: "退出",
                click: () => {
                  store.commit("setD", {
                    key: "token",
                    value: {}
                  });
                  store.commit("setD", {
                    key: "user",
                    value: {}
                  });
                  const url = router.resolve({
                    name: "login"
                  });
                  window.open(url.href, "_self");
                }
              }]
            });
          }
        }
      }]
    });
    // 修改密码
    const password = reactive({
      pwdShow: false,
      pwdRef: null,
      // 关闭
      pwdClose: () => {
        password.pwdShow = false;
      },
      // 成功
      pwdSuccess: () => {
        password.pwdClose();
        dialog.dRef.render({
          icon: "success",
          title: "密码修改成功"
        });
      }
    });
    return {
      ...toRefs(base),
      ...toRefs(dialog),
      ...toRefs(btns),
      ...toRefs(password)
    };
  }
});