import store from "../store";
/**
 * 获取get地区area名称name
 * @param {string | number} code 编码
 * @param {boolean} all 所有
 * @return {string} name 名称
 */
export function getAreaName(
  code: string | number,
  all: boolean = false
): string {
  const areas: Record<any, any> = store.getters.areas;
  const area: Record<any, any> = areaMatch(code, areas);
  const names: Record<any, any> = [];
  area.forEach((item: Record<any, any>) => {
    names.push(item.label);
  });
  const name = all ? names.join(" - ") : names[names.length - 1];
  return name;
}
/**
 * inside
 * 地区area匹配match
 * @param {string | number} code 编码
 * @param {Record<any, any>} areas 地区
 * @return {Record<any, any>} area 地区
 */
function areaMatch(
  code: string | number,
  areas: Record<any, any>
): Record<any, any> {
  const area: Record<any, any> = [];
  try {
    areas.forEach((item: Record<any, any>) => {
      if (item.code === code) {
        area.unshift(item);
      } else if (item.children) {
        const children: Record<any, any> = areaMatch(code, item.children);
        if (children.length > 0) {
          // 子节点
          area.push.apply(area, children);
          // 当前节点
          area.unshift(item);
        }
      }
      if (area.length > 0) {
        throw new Error("found");
      }
    });
  } catch (error) {}
  return area;
}
/**
 * 获取get地区areas通过by层级level
 * @param {string} levelKey 层级
 * @return {Record<any, any>} areaList 地区
 */
export function getAreasByLevel(levelKey: string): Record<any, any> {
  const areas: Record<any, any> = store.getters.areas;
  const levels: Record<string, number> = { p: 1, c: 2, d: 3 };
  const level = levels[levelKey];
  const areaList = areaMatchByLevel(areas, level);
  return areaList;
}
/**
 * inside
 * 地区area匹配match通过by层级level
 * @param {Record<any, any>} areas 地区
 * @param {number} level 层级
 * @return {Record<any, any>} areaList 地区
 */
function areaMatchByLevel(
  areas: Record<any, any>,
  level: number
): Record<any, any> {
  const areaList: Record<any, any> = [];
  const currentLevel = level - 1;
  areas.forEach((aI: Record<any, any>) => {
    const area: Record<any, any> = {
      code: aI.code,
      label: aI.label,
      value: aI.value,
    };
    if (aI.children !== undefined && currentLevel !== 0) {
      area.children = areaMatchByLevel(aI.children, currentLevel);
    }
    areaList.push(area);
  });
  return areaList;
}
