import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_n_cascader = _resolveComponent("n-cascader");
  const _component_n_input_group = _resolveComponent("n-input-group");
  const _component_n_popover = _resolveComponent("n-popover");
  return _openBlock(), _createBlock(_component_n_popover, {
    ref: "ref",
    placement: "bottom",
    trigger: "click"
  }, {
    trigger: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    default: _withCtx(() => [_createVNode(_component_n_input_group, null, {
      default: _withCtx(() => [_createVNode(_component_n_cascader, {
        filterable: "",
        "expand-trigger": "hover",
        value: _ctx.value,
        "onUpdate:value": [_cache[0] || (_cache[0] = $event => _ctx.value = $event), _ctx.update],
        placeholder: _ctx.placeholder,
        options: _ctx.options
      }, null, 8, ["value", "placeholder", "options", "onUpdate:value"])]),
      _: 1
    })]),
    _: 3
  }, 512);
}