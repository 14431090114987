import { defineComponent, reactive, onMounted, toRefs } from 'vue';
export default defineComponent({
  components: {},
  setup() {
    const clock = reactive({
      time: 0,
      init: () => {
        clock.time = new Date().getTime() * 1;
        clock.run();
      },
      run: () => {
        setInterval(() => {
          clock.time += 1000;
        }, 1000);
      }
    });
    onMounted(() => {
      clock.init();
    });
    return {
      ...toRefs(clock)
    };
  }
});