import { defineComponent, reactive, shallowRef, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
// import { defineComponent, reactive, shallowRef, toRefs } from "vue";
import * as echarts from "echarts";
export default defineComponent({
  props: {
    data: {
      type: Object,
      default: {}
    },
    top: {
      type: Number,
      default: 0
    }
    // dataNum: {
    //   type: Number,
    //   default: 0,
    // },
  },

  setup(props, {
    emit
  }) {
    const mainColor = ['rgba(252, 128, 45, 1)', '#69B7F1', 'rgba(45, 48, 252, 1)'];
    const qColor = ['rgb(255, 171, 117)', '#DCEDFB', 'rgba(125, 128, 255, 1)'];
    //     rgba(252, 128, 45, 1)
    // rgb(255, 171, 117)
    const chartBox = reactive({
      pieRef: null,
      pieCase: shallowRef(),
      opts: {
        tooltip: {
          confine: true,
          textStyle: {
            color: "#BCE9FC",
            fontSize: 12,
            align: "left"
          },
          backgroundColor: "rgba(0, 49, 85, 1)",
          borderColor: "rgba(0, 151, 251, 1)",
          trigger: 'axis'
        },
        grid: {
          left: '4%',
          right: '4%',
          // bottom: '4%',
          // top: '10%',
          // left: 0,
          // right: 0,
          bottom: '4%',
          top: '10%',
          containLabel: true
        },
        legend: {
          icon: 'rect',
          orient: 'horizontal',
          left: 'right',
          itemWidth: 12,
          itemHeight: 12,
          formatter: ['{a|{name}}'].join('\n'),
          textStyle: {
            fontSize: 12,
            color: '#6A93B9',
            height: 8,
            rich: {
              a: {
                verticalAlign: 'bottom'
              }
            }
          }
        },
        xAxis: {
          type: 'category',
          data: []
          // boundaryGap: false,
          // axisLine: {
          //   lineStyle: {
          //     color: 'rgba(255,255,255,0.45)',
          //   },
          // },
          // axisLabel: {
          //   fontSize: 12,
          //   color: '#000',
          // },
          // axisTick: {
          //   show: false,
          // },
        },

        yAxis: [{
          // show: false,
          type: 'value',
          min: 0,
          minInterval: 1,
          // splitArea: {
          //   show: false,
          // },
          // axisLine: {
          //   show: false,
          // },
          // axisTick: {
          //   show: false,
          // },
          // splitLine: {
          //   lineStyle: {
          //     color: 'rgba(255, 255, 255, 0.15)',
          //     // type: 'dashed', // dotted 虚线
          //   },
          // },
          axisLabel: {
            fontSize: 12,
            color: '#000',
            fontFamily: 'Bebas'
          }
        }, {
          type: 'value',
          show: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            fontSize: 12,
            formatter: '{value}%',
            fontFamily: 'Bebas',
            color: '#000'
          },
          splitArea: {
            show: false
          }
        }],
        series: [{
          type: 'line',
          // yAxisIndex: 1, // 使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
          // smooth: false, // 平滑曲线显示
          // symbol: 'circle', // 标记的图形为实心圆
          // symbolSize: 8, // 标记的大小
          // itemStyle: {
          //   color: '#65CF9B',
          //   borderColor: '#fff', // 圆点透明 边框
          //   borderWidth: 1,
          // },
          // name: '增长率(%)',
          data: [],
          itemStyle: {
            color: mainColor[props.top]
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: qColor[props.top]
            }, {
              offset: 1,
              color: '#fff'
            }])
          },
          smooth: true,
          showSymbol: false
        }]
      },
      process: () => {
        const data = props.data;
        // console.log(data)
        chartBox.opts.xAxis.data = [];
        chartBox.opts.series[0].data = [];
        // // chartBox.opts.series[1].data = [];
        // // data.forEach((item: Record<any, any>, idx: number) => {
        // //   const moduleTitle: Record<any, any> = data.deathOrStokEntCount;
        // //   const moduleRate: Record<any, any> = data.deathOrStokEntSpeed;
        // //   chartBox.opts.series[0].data.push(moduleTitle);
        // //   chartBox.opts.series[1].data.push(moduleRate);
        // // });
        data.forEach((item, idx) => {
          const moduleTitle = item.loginCount;
          chartBox.opts.series[0].data.push(moduleTitle);
        });
        // // data.deathOrStokEntSpeed.forEach((item: any, idx: number) => {
        // //   // const moduleRate: number = Math.floor(item * 100);
        // //   const moduleRate: number = item * 100;
        // //   const num = moduleRate.toFixed(2)
        // //   chartBox.opts.series[1].data.push(num);
        // // });
        data.forEach((item, idx) => {
          const x = item.reqTime;
          const menu1Item = x;
          chartBox.opts.xAxis.data.push(menu1Item);
          // console.log(chartBox.opts.xAxis.data)
        });
      },

      draw: async () => {
        // await chartBox.ready();
        chartBox.process();
        // console.log(0)
        if (chartBox.pieRef && chartBox.pieCase) {
          // await chartBox.ready();
          chartBox.pieCase.setOption(chartBox.opts, true);
          chartBox.refresh();
          // console.log(1)
        } else {
          await chartBox.ready();
          // main
          chartBox.pieCase = echarts.init(chartBox.pieRef);
          chartBox.pieCase.setOption(chartBox.opts, true);
          // console.log(2)
          // window resize
          window.addEventListener("resize", () => {
            chartBox.refresh();
          });
          // box resize
          const observer = new ResizeObserver(() => {
            chartBox.refresh();
            // console.log(4)
          });

          observer.observe(chartBox.pieRef);
          // console.log(5)
        }
      },

      refresh: () => {
        chartBox.pieCase.resize(chartBox.pieRef);
      },
      // READY
      ready: async () => {
        return new Promise(resolve => {
          const ready = setInterval(() => {
            const bar = chartBox.pieRef;
            if (bar && bar.clientHeight > 0) {
              clearInterval(ready);
              resolve();
            }
          }, 100);
        });
      }
    });
    return {
      ...toRefs(chartBox)
    };
  },
  components: {}
});