import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, shallowRef, toRefs } from "vue";
export default defineComponent({
  components: {},
  props: {
    code: {
      type: Number,
      default: 100000
    }
  },
  setup(props, {
    emit
  }) {
    const mapBox = reactive({
      ref: shallowRef(),
      case: shallowRef(),
      loca: shallowRef(),
      marker: null,
      layer: shallowRef(),
      current: shallowRef(),
      district: shallowRef(),
      textDatas: [],
      texts: [],
      areaDatas: {},
      legend: null,
      colors: ["rgba(198, 198, 255, 1)", "rgba(181, 181, 255, 1)", "rgba(155, 155, 255, 1)", "rgba(134, 134, 255, 1)", "rgba(58, 58, 255, 1)"],
      // icons: {
      //   park: require("@/assets/icon/park.png"),
      //   build: require("@/assets/icon/qiye.png"),
      // } as Record<any, any>,
      // 初始化 amap loca map
      show: async () => {
        const AMap = await mapBox.checkAMap();
        if (AMap) {
          const map = new AMap.Map(mapBox.ref, {
            zoom: 4.2,
            zooms: [3.6, 20],
            center: [106, 39],
            // mapStyle: "amap://styles/darkblue",
            showLabel: false
            // zoomEnable: false,
          });

          mapBox.case = map;
          // loca
          const Loca = await mapBox.checkLoca();
          mapBox.loca = new Loca.Container({
            map: map
          });
        }
      },
      // 地图定位
      location: async code => {
        // console.log(code)
        await mapBox.ready();
        // 当前区域判断
        const current = mapBox.current;
        if (current && current.getAdcode() === code) {
          return;
        }
        // create de
        if (!mapBox.district) {
          const district = await mapBox.uiDistrict();
          mapBox.district = district;
          // 监听hover
          mapBox.hover();
          // 监听click
          mapBox.click();
        }
        // 加载区域
        mapBox.loadAreaNode(code, (error, areaNode) => {
          // name
          const name = areaNode.getProps().name;
          emit("name", name);
          mapBox.current = areaNode;
          const district = mapBox.district;
          district.setAreaNodesForLocating([mapBox.current]);
          mapBox.refreshAreaNode();
        });
      },
      // 坐标定位
      locationCoord: coord => {
        mapBox.case.setCenter(coord.coordinate);
      },
      // 数据渲染
      render: async builds => {
        // console.log(builds)
        await mapBox.ready();
        const AMap = await mapBox.checkAMap();
        // marker
        const hoverMarker = new AMap.Marker({
          anchor: "bottom-left"
        });
        mapBox.marker = hoverMarker;
        // point data
        const labelsMarker = [];
        builds.forEach(item => {
          const labelMarker = mapBox.createMarker(AMap, item);
          labelsMarker.push(labelMarker);
        });
        // console.log(labelsMarker)
        // layer
        const labelsLayer = new AMap.LabelsLayer({
          zooms: [3, 20],
          zIndex: 1000,
          collision: false
        });
        mapBox.layer = labelsLayer;
        labelsLayer.add(labelsMarker);
        const map = mapBox.case;
        map.add(labelsLayer);
        mapBox.draws();
        // mapBox.location();
        // map.setFitView(labelsMarker, false, [120, 60, 200, 60]);
        // mapBox.case.setZoom(16);
      },

      renders: async areas => {
        // console.log(areas)
        await mapBox.ready();
        // duration
        const duration = mapBox.calcDuration(areas);
        // legend
        // mapBox.drawLegend(duration);
        // area data
        const areaDatas = {};
        areas.forEach(item => {
          // code->value
          areaDatas[item.code] = {
            percent: item.percent,
            // value: item.value,
            level: Math.ceil(item.value / duration) > 0 ? Math.ceil(item.value / duration) - 1 : 0
          };
        });
        mapBox.areaDatas = areaDatas;
        mapBox.draw();
      },
      // 数据更新
      rerender: async builds => {
        const AMap = await mapBox.checkAMap();
        const labelsMarker = [];
        builds.forEach(item => {
          const labelMarker = mapBox.createMarker(AMap, item);
          labelsMarker.push(labelMarker);
        });
        const labelsLayer = mapBox.layer;
        labelsLayer.clear();
        labelsLayer.add(labelsMarker);
        const map = mapBox.case;
        map.setFitView(labelsMarker, false, [120, 60, 200, 60]);
      },
      calcDuration: areas => {
        const number = mapBox.colors.length;
        const max = Math.max.apply(Math, areas.map(item => {
          return item.value;
        }));
        const duration = Math.ceil(Math.ceil(max * 10 + 5) / 10 / number / 100) * 100;
        return duration;
      },
      // 加载区域
      loadAreaNode: (code, callback) => {
        const district = mapBox.district;
        district.loadAreaNode(code, (error, areaNode) => {
          if (error) {
            callback(error);
            return;
          }
          if (callback) {
            callback(null, areaNode);
          }
        });
      },
      // 创建marker
      createMarker: (AMap, build) => {
        // console.log(build.regCapNum)
        const map = mapBox.case;
        const hoverMarker = mapBox.marker;
        const marker = {
          position: build.coord,
          extData: {
            key: build.key,
            name: build.name,
            tag: build.tag,
            tagName: build.tagName
          },
          // zIndex: item.score,
          text: {
            content: build.name + ' ' + ' ',
            // direction: "center",
            offset: [-25, -2],
            style: {
              fillColor: "#fff",
              fontSize: 12,
              padding: '2, 5',
              backgroundColor: build.regCapNum < 1000 ? 'rgba(246, 137, 38, 0.8)' : build.regCapNum >= 1000 && build.regCapNum < 5000 ? 'rgba(41, 122, 236, 0.8)' : 'rgba(38, 152, 70, 0.8)'
              // borderColor: '#fff',
              // borderWidth: 1,
            }
          }
          // icon: {
          //   type: "image",
          //   image: mapBox.icons[build.tag],
          //   size: [37, 37],
          //   anchor: "center",
          // },
        };

        const labelMarker = new AMap.LabelMarker(marker);
        labelMarker.on("click", e => {
          const markData = labelMarker.getExtData();
          if (markData.key) {
            emit("item-click", {
              key: markData.key,
              tag: markData.tag
            });
          }
        });
        labelMarker.on("mouseover", e => {
          // console.log(e)
          const position = e.data.data && e.data.data.position;
          // console.log(position)
          const markData = labelMarker.getExtData();
          if (position && markData.name) {
            hoverMarker.setContent(`
                <div class="amap-build-window">
                  <div class="amap-build-box">
                    ${markData.tagName} ${markData.name}
                  </div>
                  <div class="amap-build-sharp"></div>
                </div>
              `);
            hoverMarker.setPosition(position);
            map.add(hoverMarker);
          }
        });
        labelMarker.on("mouseout", () => {
          map.remove(hoverMarker);
        });
        return labelMarker;
      },
      markName: async data => {
        const AMap = await mapBox.checkAMap();
        const map = mapBox.case;
        const marker = {
          text: data.name,
          anchor: 'center',
          draggable: false,
          cursor: 'pointer',
          angle: 0,
          style: {
            'padding': '3px 7px',
            'margin-bottom': '20px',
            'border-radius': '0',
            'background-color': 'white',
            'width': '150px',
            'border-width': 0,
            'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
            'text-align': 'left',
            'font-size': '14px',
            'color': '#000',
            'word-break': 'inherit',
            'white-space': 'inherit',
            'overflow': 'auto'
          },
          position: data.coord
        };
        const labelMarker = new AMap.Text(marker);
        labelMarker.setMap(null);
        labelMarker.setMap(map);
      },
      // 刷新区域
      refreshAreaNode: () => {
        const current = mapBox.current;
        const district = mapBox.district;
        const map = mapBox.case;
        district.setHoverFeature(null);
        //更新地图视野
        map.setBounds(current.getBounds(), null, null, true);
      },
      // 图例
      // drawLegend: async (duration: number) => {
      //   if (mapBox.legend) {
      //     const legend: any = mapBox.legend;
      //     legend.remove();
      //   }
      //   const number = mapBox.colors.length;
      //   const legendData: Record<any, any> = [];
      //   for (let i = 0; i < number; i++) {
      //     legendData.push({
      //       label: `${i * duration} ~ ${(i + 1) * duration}`,
      //       color: mapBox.colors[i],
      //     });
      //   }
      //   mapBox.legend = new (window as any).Loca.Legend({
      //     loca: mapBox.loca,
      //     title: { label: "" },
      //     style: {
      //       backgroundColor: "rgba(0, 0, 0, 0.1)",
      //       left: "20px",
      //       bottom: "20px",
      //     },
      //     dataMap: legendData.reverse(),
      //   });
      // },
      // 绘制内容
      draw: async () => {
        await mapBox.checkDist();
        const district = mapBox.district;
        // 清除已有绘制内容
        district.clearFeaturePolygons();
        // 区域
        // mapBox.drawArea();
        mapBox.drawMarker();
        // 子区域
        mapBox.drawSub();
        // 子区域文字
        mapBox.drawText();
      },
      draws: async () => {
        await mapBox.checkDist();
        const district = mapBox.district;
        // 清除已有绘制内容
        district.clearFeaturePolygons();
        // 区域
        // mapBox.drawArea();
        mapBox.drawMarker();
        // 子区域
        // mapBox.drawSub();
        // 子区域文字
        mapBox.drawText();
      },
      // 绘制hover点
      drawMarker: () => {
        const map = mapBox.case;
        if (mapBox.marker) {
          map.remove(mapBox.marker);
          mapBox.marker = null;
        }
        const marker = new window.AMap.Marker({
          map: map,
          // cursor: "pointer",
          bubble: true,
          offset: [0, -10],
          zIndex: 10000
        });
        marker.hide();
        mapBox.marker = marker;
      },
      // 绘制子区域
      drawSub: () => {
        const datas = mapBox.areaDatas;
        mapBox.textDatas = [];
        const current = mapBox.current;
        const district = mapBox.district;
        const colors = mapBox.colors;
        district.renderSubFeatures(current, feature => {
          const properties = feature.properties;
          const code = properties.adcode;
          // texts
          if (datas[code]) {
            const textData = {
              code: code,
              name: properties.name,
              coord: properties.center,
              percent: datas[code].percent
            };
            mapBox.textDatas.push(textData);
          }
          // // area
          const level = datas[code] ? datas[code].level : -1;
          const fillColor = level === -1 ? "transparent" : colors[level];
          const strokeColor = level === -1 ? "transparent" : colors[level];
          const ab = {
            cursor: "pointer",
            bubble: true,
            fillOpacity: 0.5,
            fillColor: fillColor,
            strokeOpacity: 1,
            strokeWeight: 1,
            strokeColor: strokeColor // 线颜色
          };

          return ab;
        });
      },
      // 子区域文字
      drawText: () => {
        const map = mapBox.case;
        // 清除已存
        map.remove(mapBox.texts);
        mapBox.texts = [];
        const textDatas = mapBox.textDatas;
        const zoomStyle = {};
        for (let i = 3; i <= 20; i++) {
          zoomStyle[i] = 0;
        }
        // 创建文字
        // textDatas.forEach((item: Record<any, any>) => {
        //   const text = new (window as any).AMap.Text({
        //     map: map,
        //     position: item.coord,
        //     text: `${item.name} ${item.percent}%`,
        //     zIndex: item.percent * 100,
        //     anchor: "center",
        //     style: {
        //       color: "#FFF",
        //       fontSize: "12px",
        //       border: "0 none",
        //       // background: "none",
        //       background: "#f60",
        //       // color: "#000",
        //       padding: "4px",
        //     },
        //     bubble: true,
        //   });
        //   mapBox.texts.push(text);
        // });
      },

      // 绘制区域
      // drawArea: () => {
      //   const current: any = mapBox.current;
      //   const district: any = mapBox.district;
      //   district.renderParentFeature(current, {
      //     cursor: "default",
      //     bubble: true,
      //     // strokeColor: "#fff",
      //     strokeColor: "#000",
      //     strokeOpacity: 1,
      //     strokeWeight: 1,
      //     fillColor: current.getSubFeatures().length
      //       ? null
      //       : "rgba(0, 299, 255, 1)",
      //     fillOpacity: 0.35,
      //   });
      // },
      // 监听hover
      hover: () => {
        const district = mapBox.district;
        // 区域间
        district.on("featureMouseout featureMouseover", (e, feature) => {
          const datas = mapBox.areaDatas;
          const code = feature.properties.adcode;
          if (!feature || !datas[code]) {
            return;
          }
          // hover
          const isHover = e.type === "featureMouseover";
          const marker = mapBox.marker;
          // console.log(marker)
          if (marker) {
            if (isHover) {
              const properties = feature.properties;
              // console.log(properties)
              const areaData = datas[code];
              // 更新提示内容
              const markerContent = `
                <div class="area-marker">
                  <div class="area-marker-info">
                    <span>${properties.name}</span>
                  </div>
                </div>
              `;
              // <span>${areaData.percent}%</span>
              marker.setContent(markerContent);
              // 更新位置
              const lnglat = e.originalEvent ? e.originalEvent.lnglat : null;
              marker.setPosition(lnglat);
              marker.show();
            } else {
              marker.hide();
            }
          }
          // 当前区块
          var polygons = district.findFeaturePolygonsByAdcode(code);
          // 更新区块样式
          polygons.forEach(polygon => {
            polygon.setOptions({
              fillOpacity: isHover ? 0.7 : 0.5
            });
          });
        });
        // 区域内
        district.on("featureMousemove", e => {
          const marker = mapBox.marker;
          if (marker) {
            marker.setPosition(e.originalEvent.lnglat);
          }
        });
      },
      // 监听click
      click: () => {
        const district = mapBox.district;
        // console.log(district)
        district.on("featureClick", (e, feature) => {
          // const datas: Record<any, any> = mapBox.areaDatas;
          const code = feature.properties.adcode;
          // const level = feature.properties.level;
          // if (!feature || !datas[code] || level === "district") {
          //   return;
          // }
          // console.log(feature.properties.adcode)
          emit("change", code);
        });
        district.on("outsideClick", e => {
          // 当前父级
          const current = mapBox.current;
          const areaProp = current.getProps();
          const currentParent = areaProp.acroutes ? areaProp.acroutes[areaProp.acroutes.length - 1] : null;
          district.locatePosition(e.originalEvent.lnglat, (_, routeFeatures) => {
            if (currentParent) {
              const parent = routeFeatures.findIndex(item => item.properties.adcode === currentParent);
              const floor = routeFeatures.findIndex(item => item.properties.level === areaProp.level);
              const target = parent >= 0 ? routeFeatures[floor].properties.adcode : currentParent;
              emit("change", target);
            }
          });
        });
      },
      // AMAP
      checkAMap: async () => {
        return new Promise(resolve => {
          const checkAMap = setInterval(() => {
            if (window.AMap) {
              clearInterval(checkAMap);
              resolve(window.AMap);
            }
          }, 100);
        });
      },
      // LOCA
      checkLoca: async () => {
        return new Promise(resolve => {
          const checkLoca = setInterval(() => {
            if (window.Loca) {
              clearInterval(checkLoca);
              resolve(window.Loca);
            }
          }, 100);
        });
      },
      // Dist
      checkDist: async () => {
        return new Promise(resolve => {
          const checkDist = setInterval(() => {
            if (mapBox.current && mapBox.district) {
              clearInterval(checkDist);
              resolve();
            }
          }, 100);
        });
      },
      // READY
      ready: async () => {
        return new Promise(resolve => {
          const ready = setInterval(() => {
            if (mapBox.case && mapBox.loca) {
              clearInterval(ready);
              resolve();
            }
          }, 100);
        });
      },
      // DISTRICT
      uiDistrict: async () => {
        return new Promise(resolve => {
          window.AMapUI.loadUI(["geo/DistrictExplorer"], DistrictExplorer => {
            const districtExplorer = new DistrictExplorer({
              eventSupport: true,
              map: mapBox.case
            });
            resolve(districtExplorer);
          });
        });
      }
    });
    return {
      ...toRefs(mapBox)
    };
  }
});