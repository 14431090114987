import store from "../store";

/**
 * 获取get行业ind行业h名称name
 * @param {number} ind 行业key
 * @return {string} name 名称
 */
export function getIndHName(ind: number): string {
  const indhs: Record<any, any> = store.getters.indh;
  const indh: Record<any, any> = indHMatch(ind, indhs);
  const names: Record<any, any> = [];
  indh.forEach((item: Record<any, any>) => {
    names.push(item.label);
  });
  const name = names.join(" - ");
  return name;
}

/**
 * inside
 * 行业ind行业h匹配match
 * @param {number} ind 行业key
 * @param {Record<any, any>} indhs 行业
 * @return {Record<any, any>} indh 行业
 */
function indHMatch(ind: number, indhs: Record<any, any>): Record<any, any> {
  const indh: Record<any, any> = [];
  try {
    indhs.forEach((item: Record<any, any>) => {
      if (item.value === ind) {
        indh.unshift(item);
      }
      if (indh.length > 0) {
        throw new Error("found");
      }
    });
  } catch (error) {}
  return indh;
}

/**
 * 获取get行业ind行业h值value
 * @param {string} name 行业name
 * @return {number} value key
 */
export function getIndHValue(name: string): number {
  const indhs: Record<any, any> = store.getters.indh;
  const indh: Record<any, any> = indhs.find(
    (item: Record<any, any>) => item.label === name
  );
  const value = indh ? indh.value : undefined;
  return value;
}

/**
 * 获取get行业ind行业hs
 * @return {Record<any, any>} indhs 行业
 */
export function getIndHs(): Record<any, any> {
  const indhs: Record<any, any> = store.getters.indh;
  return indhs;
}

/**
 * 获取get行业ind行业ts
 * @return {Record<any, any>} indts 行业
 */
export function getIndTs(): Record<any, any> {
  const indts: Record<any, any> = store.getters.indt;
  indts.sort((x: Record<any, any>, y: Record<any, any>) => {
    const ak = x.primInduCode,
      bk = y.primInduCode,
      af: any = isFinite(ak),
      bf: any = isFinite(bk);
    return (af != bf && bf - af) || (af && bf ? ak - bk : ak.localeCompare(bk));
  });
  const datas: Record<any, any> = [];
  indts.forEach((indt: Record<any, any>) => {
    const children: Record<any, any> = [];
    if (indt.secList) {
      indt.secList.forEach((sub: Record<any, any>) => {
        if (sub.secInduCode !== indt.primInduCode) {
          children.push({ label: sub.secnduName, value: sub.secInduCode });
        }
      });
    }
    datas.push({
      label: indt.primInduName,
      value: indt.primInduCode,
      children: children,
    });
  });
  return datas;
}
