import { defineComponent, reactive, shallowRef, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
// import { defineComponent, reactive, shallowRef, toRefs } from "vue";
import * as echarts from "echarts";
export default defineComponent({
  props: {
    data: {
      type: Object,
      default: {}
    },
    top: {
      type: Number,
      default: 0
    }
    // dataNum: {
    //   type: Number,
    //   default: 0,
    // },
  },

  setup(props, {
    emit
  }) {
    const mainColor = ['rgba(252, 128, 45, 1)', '#69B7F1', 'rgba(45, 48, 252, 1)'];
    const qColor = ['rgb(255, 171, 117)', '#DCEDFB', 'rgba(125, 128, 255, 1)'];
    //     rgba(252, 128, 45, 1)
    // rgb(255, 171, 117)
    let colors = ['rgba(13,213,236,1)', "rgba(0,121,254,0.7)"];
    const chartBox = reactive({
      pieRef: null,
      pieCase: shallowRef(),
      opts: {
        tooltip: {
          confine: true,
          textStyle: {
            color: "#BCE9FC",
            fontSize: 12,
            align: "left"
          },
          backgroundColor: "rgba(0, 49, 85, 1)",
          borderColor: "rgba(0, 151, 251, 1)",
          trigger: 'axis'
        },
        grid: {
          left: '2%',
          right: '10%',
          bottom: '4%',
          top: '10%',
          containLabel: true
        },
        legend: {
          icon: 'rect',
          orient: 'horizontal',
          left: 'right',
          itemWidth: 12,
          itemHeight: 12,
          formatter: ['{a|{name}}'].join('\n'),
          textStyle: {
            fontSize: 12,
            color: '#6A93B9',
            height: 8,
            rich: {
              a: {
                verticalAlign: 'bottom'
              }
            }
          }
        },
        xAxis: [{
          splitLine: {
            show: false
          },
          type: 'value',
          show: false
        }],
        yAxis: [{
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          type: 'category',
          axisTick: {
            show: false
          },
          inverse: true,
          data: [],
          axisLabel: {
            // color: '#BFF7FF',
            fontSize: 14
          }
        }],
        series: [{
          // name: '标准化',
          type: 'bar',
          barWidth: 10,
          label: {
            show: true,
            position: 'right',
            color: 'rgba(0,121,254,1)',
            fontSize: 14,
            distance: 8,
            formatter: '{c} ' // 这里是数据展示的时候显示的数据
          },

          itemStyle: {
            normal: {
              barBorderRadius: [10, 10, 10, 10],
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                offset: 0,
                color: colors[0]
              }, {
                offset: 1,
                color: colors[1]
              }])
            }
          },
          data: []
        }]
      },
      process: () => {
        const data = props.data;
        console.log(data);
        if (data.length != undefined) {
          // console.log(1)
          chartBox.opts.yAxis[0].data = [];
          chartBox.opts.series[0].data = [];
          // // chartBox.opts.series[1].data = [];
          // // data.forEach((item: Record<any, any>, idx: number) => {
          // //   const moduleTitle: Record<any, any> = data.deathOrStokEntCount;
          // //   const moduleRate: Record<any, any> = data.deathOrStokEntSpeed;
          // //   chartBox.opts.series[0].data.push(moduleTitle);
          // //   chartBox.opts.series[1].data.push(moduleRate);
          // // });
          data.forEach((item, idx) => {
            const moduleTitle = item.loginCount;
            chartBox.opts.series[0].data.push(moduleTitle);
          });
          // // data.deathOrStokEntSpeed.forEach((item: any, idx: number) => {
          // //   // const moduleRate: number = Math.floor(item * 100);
          // //   const moduleRate: number = item * 100;
          // //   const num = moduleRate.toFixed(2)
          // //   chartBox.opts.series[1].data.push(num);
          // // });
          data.forEach((item, idx) => {
            const x = item.reqTime;
            const menu1Item = x;
            chartBox.opts.yAxis[0].data.push(menu1Item);
            // console.log(chartBox.opts.xAxis.data)
          });
        } else {
          console.log(2);
        }
      },
      draw: async () => {
        // await chartBox.ready();
        chartBox.process();
        // console.log(0)
        if (chartBox.pieRef && chartBox.pieCase) {
          // await chartBox.ready();
          chartBox.pieCase.setOption(chartBox.opts, true);
          chartBox.refresh();
          // console.log(1)
        } else {
          await chartBox.ready();
          // main
          chartBox.pieCase = echarts.init(chartBox.pieRef);
          chartBox.pieCase.setOption(chartBox.opts, true);
          // console.log(2)
          // window resize
          window.addEventListener("resize", () => {
            chartBox.refresh();
          });
          // box resize
          const observer = new ResizeObserver(() => {
            chartBox.refresh();
            // console.log(4)
          });

          observer.observe(chartBox.pieRef);
          // console.log(5)
        }
      },

      refresh: () => {
        chartBox.pieCase.resize(chartBox.pieRef);
      },
      // READY
      ready: async () => {
        return new Promise(resolve => {
          const ready = setInterval(() => {
            const bar = chartBox.pieRef;
            if (bar && bar.clientHeight > 0) {
              clearInterval(ready);
              resolve();
            }
          }, 100);
        });
      }
    });
    return {
      ...toRefs(chartBox)
    };
  },
  components: {}
});