import { defineComponent, reactive, computed, shallowRef, nextTick, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
import * as echarts from "echarts";
export default defineComponent({
  components: {},
  props: {
    title: {
      type: String,
      default: ""
    },
    datas: {
      type: Array,
      default: []
    },
    textColor: {
      type: String,
      default: "#000"
    }
  },
  setup(props, {
    emit
  }) {
    // colors
    const colors = ["rgba(2, 246, 144, 1)", "rgba(51, 255, 248, 1)", "rgba(201, 231, 22, 1)", "rgba(1, 187, 252, 1)", "rgba(9, 9, 255, 1)"];
    const tool = reactive({
      hTool: 20,
      lg: (direction, colors) => {
        const k1 = direction === "rl" ? 1 : 0;
        const k2 = direction === "bt" ? 1 : 0;
        const k3 = direction === "lr" ? 1 : 0;
        const k4 = direction === "tb" ? 1 : 0;
        const interval = colors.length - 1 > 0 ? 1 / (colors.length - 1) : 0;
        const colorBox = [];
        colors.forEach((c, idx) => {
          colorBox.push({
            offset: c[1] ? c[1] : idx * interval,
            color: c[0]
          });
        });
        return new echarts.graphic.LinearGradient(k1, k2, k3, k4, colorBox);
      },
      animatedTime: 2000
    });
    const chart = reactive({
      ready: computed(() => {
        chart.draw();
        return props.datas.length > 0;
      }),
      // 对象
      ref: null,
      // 实例
      chart: shallowRef(),
      // 配置
      opts: {
        color: colors,
        // tooltip
        tooltip: {
          show: false
        },
        grid: {
          left: 0,
          right: 0,
          top: 60,
          bottom: 0,
          containLabel: true
        },
        title: {
          text: props.title
        },
        series: [{
          type: "pie",
          clockwise: false,
          center: ["50%", "50%"],
          radius: ["45%", "55%"],
          label: {
            show: true,
            fontSize: 12,
            position: "outside",
            formatter: i => {
              return i.data.name ? `${i.data.name}\n{white|${i.data.value.toFixed(1)}%}` : "";
            },
            rich: {
              white: {
                align: "center",
                padding: [0, 3]
              }
            }
          },
          labelLine: {
            show: true,
            showAbove: true
          },
          itemStyle: {
            borderWidth: 5,
            shadowBlur: 6
          },
          animationType: "scale",
          animationEasing: "elasticOut",
          animationDelay: idx => idx * 50,
          tooltip: {
            show: true,
            confine: true,
            formatter: "{b} {c}%"
          },
          data: []
        }]
      },
      // 画
      draw: () => {
        nextTick(() => {
          chart.process();
          chart.render();
          // chart.animate();
          effect.render();
        });
      },
      // 数据处理
      process: () => {
        const datas = props.datas;
        const sDatas = [];
        datas.forEach((i, idx) => {
          const color = chart.opts.color[idx % chart.opts.color.length];
          sDatas.push({
            name: i.label,
            value: i.value,
            label: {
              // color: color === "rgba(9, 9, 255, 1)" ? props.textColor : color,
              color: props.textColor ? props.textColor : color
            },
            itemStyle: {
              shadowColor: color,
              borderColor: color
            }
          });
          sDatas.push({
            value: 5,
            name: "",
            itemStyle: {
              label: {
                show: false
              },
              labelLine: {
                show: false
              },
              color: "transparent"
            },
            tooltip: {
              show: false
            }
          });
        });
        chart.opts.series[0].data = sDatas;
      },
      // 渲染
      render: () => {
        // 已渲染
        if (chart.ref && chart.chart) {
          chart.chart.setOption(chart.opts, true);
          chart.rerender();
        } else {
          chart.chart = echarts.init(chart.ref);
          setTimeout(() => {
            chart.chart.setOption(chart.opts);
          }, 800);
          // window resize
          window.addEventListener("resize", () => {
            chart.rerender();
          });
          // box resize
          new ResizeObserver(() => {
            chart.rerender();
          }).observe(chart.ref);
          // finish
          chart.chart.on("finished", () => {
            // finished
          });
          // click
          chart.chart.on("click", e => {
            if (e.name !== "") {
              // const param = { name: e.name, value: e.value };
              emit("get-i", e.name);
            }
          });
        }
      },
      // 重新渲染
      rerender: () => {
        chart.chart.resize(chart.ref);
      },
      // 销毁
      destroy: () => {
        chart.chart.dispose();
      },
      // 动态
      animated: null,
      animate: () => {
        // 清除
        if (chart.animated) {
          clearInterval(chart.animated);
        }
        let s = 0;
        let i = 0;
        const series = chart.opts.series;
        chart.animated = setInterval(() => {
          if (chart.ref && chart.ref.offsetHeight > 0) {
            chart.chart.dispatchAction({
              type: "showTip",
              seriesIndex: s,
              dataIndex: i
            });
          }
          if (series[s].data[i + 1] === undefined) {
            i = 0;
            if (s + 2 === series.length || !series[s + 1]) {
              s = 0;
            } else {
              s++;
            }
          } else {
            i++;
          }
        }, tool.animatedTime);
      }
    });
    // 特效
    const effect = reactive({
      effectRef: null,
      effectChart: shallowRef(),
      effectOpts: {
        tooltip: {
          show: false
        },
        series: []
      },
      angle: 0,
      effect: () => {
        effect.angle = effect.angle + 3;
        effect.effectOpts.series = [effect.curveLine(0), effect.curveLine(1), effect.curveLine(2), effect.curveLine(3), effect.curveLine1(0), effect.curveLine1(1), effect.curveLine2(0), effect.curveLine2(1), effect.curvePoint1(0), effect.curvePoint1(1), effect.curvePoint2(0), effect.curvePoint2(1)];
        effect.effectChart.setOption(effect.effectOpts, true);
      },
      curveLine: num => {
        return {
          type: "custom",
          coordinateSystem: "none",
          renderItem: (_, api) => {
            return {
              type: "arc",
              shape: {
                cx: api.getWidth() * 0.5,
                cy: api.getHeight() * 0.5,
                r: Math.min(api.getWidth(), api.getHeight()) * 0.5 * 0.35,
                startAngle: (15 + num * 90) * Math.PI / 180,
                endAngle: (75 + num * 90) * Math.PI / 180
              },
              style: {
                stroke: "#EDB903",
                fill: "transparent",
                lineWidth: 1.5
              },
              silent: true
            };
          },
          data: [0]
        };
      },
      curveLine1: num => {
        return {
          type: "custom",
          coordinateSystem: "none",
          renderItem: (_, api) => {
            return {
              type: "arc",
              shape: {
                cx: api.getWidth() * 0.5,
                cy: api.getHeight() * 0.5,
                r: Math.min(api.getWidth(), api.getHeight()) * 0.5 * 0.6,
                startAngle: (0 + effect.angle + num * 180) * Math.PI / 180,
                endAngle: (130 + effect.angle + num * 180) * Math.PI / 180
              },
              style: {
                stroke: "#0CD3DB",
                fill: "transparent",
                lineWidth: 1.5
              },
              silent: true
            };
          },
          data: [0]
        };
      },
      curveLine2: num => {
        return {
          type: "custom",
          coordinateSystem: "none",
          renderItem: (_, api) => {
            return {
              type: "arc",
              shape: {
                cx: api.getWidth() * 0.5,
                cy: api.getHeight() * 0.5,
                r: Math.min(api.getWidth(), api.getHeight()) * 0.5 * 0.55,
                startAngle: (270 + -effect.angle + num * 180) * Math.PI / 180,
                endAngle: (270 + 130 + -effect.angle + num * 180) * Math.PI / 180
              },
              style: {
                stroke: "#0CD3DB",
                fill: "transparent",
                lineWidth: 1.5
              },
              silent: true
            };
          },
          data: [0]
        };
      },
      curvePoint1: num => {
        return {
          type: "custom",
          coordinateSystem: "none",
          renderItem: (_, api) => {
            const x0 = api.getWidth() * 0.5;
            const y0 = api.getHeight() * 0.5;
            const r = Math.min(api.getWidth(), api.getHeight()) * 0.5 * 0.6;
            const point = effect.getCirlPoint(x0, y0, r, 130 + num * 180 + effect.angle);
            return {
              type: "circle",
              shape: {
                cx: point.x,
                cy: point.y,
                r: 2
              },
              style: {
                stroke: "#0CD3DB",
                fill: "#0CD3DB"
              },
              silent: true
            };
          },
          data: [0]
        };
      },
      curvePoint2: num => {
        return {
          type: "custom",
          coordinateSystem: "none",
          renderItem: (_, api) => {
            const x0 = api.getWidth() * 0.5;
            const y0 = api.getHeight() * 0.5;
            const r = Math.min(api.getWidth(), api.getHeight()) * 0.5 * 0.55;
            const point = effect.getCirlPoint(x0, y0, r, 90 + num * 180 + -effect.angle);
            return {
              type: "circle",
              shape: {
                cx: point.x,
                cy: point.y,
                r: 2
              },
              style: {
                stroke: "#0CD3DB",
                fill: "#0CD3DB"
              },
              silent: true
            };
          },
          data: [0]
        };
      },
      getCirlPoint: (x0, y0, r, angle) => {
        const x1 = x0 + r * Math.cos(angle * Math.PI / 180);
        const y1 = y0 + r * Math.sin(angle * Math.PI / 180);
        return {
          x: x1,
          y: y1
        };
      },
      render: () => {
        if (effect.effectRef && effect.effectChart) {
          effect.rerender();
        } else {
          effect.effectChart = echarts.init(effect.effectRef);
          setInterval(() => {
            effect.effect();
          }, 50);
          // window resize
          window.addEventListener("resize", () => {
            chart.rerender();
          });
          // box resize
          new ResizeObserver(() => {
            effect.rerender();
          }).observe(effect.effectRef);
        }
      },
      rerender: () => {
        effect.effectChart.resize(effect.effectRef);
      }
    });
    return {
      ...toRefs(chart),
      ...toRefs(effect)
    };
  }
});