import { defineComponent, reactive, computed, watch, onMounted, toRefs } from 'vue';
import { useStore } from 'vuex';
import { getAreasByLevel } from "@/util/area";
export default defineComponent({
  components: {},
  props: {
    code: {
      type: String,
      default: ""
    },
    unit: {
      type: String,
      default: "d"
    }
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const region = reactive({
      ref: null,
      placeholder: "请选择地区",
      value: undefined,
      options: computed(() => {
        return store.getters.areas.length > 0 ? getAreasByLevel(props.unit) : [];
      }),
      update: (value, option) => {
        console.log(value, option);
        region.confirm();
      },
      confirm: () => {
        emit("setCode", region.value);
        region.ref.setShow(false);
      },
      reset: () => {
        region.value = props.code ? props.code : undefined;
      }
    });
    // 更新当前
    watch(() => props.code, () => {
      region.reset();
    });
    onMounted(() => {
      region.reset();
    });
    return {
      ...toRefs(region)
    };
  }
});