import { h, defineComponent, ref } from 'vue';
import { NIcon } from 'naive-ui';
// import {
//   BookOutline as BookIcon,
//   PersonOutline as PersonIcon,
//   WineOutline as WineIcon
// } from '@vicons/ionicons5'
function renderIcon(icon) {
  return () => h(NIcon, null, {
    default: () => h(icon)
  });
}
const menuOptions = [{
  label: '首页',
  key: 'homeIndex'
  // icon: renderIcon(BookIcon)
}, {
  label: '1973年的弹珠玩具',
  key: 'pinball-1973',
  // icon: renderIcon(BookIcon),
  // disabled: true,
  children: [{
    label: '鼠',
    key: 'rat'
  }]
}, {
  label: '寻羊冒险记',
  key: 'a-wild-sheep-chase'
  // disabled: true,
  // icon: renderIcon(BookIcon)
}, {
  label: '舞，舞，舞',
  key: 'dance-dance-dance',
  // icon: renderIcon(BookIcon),
  children: [{
    type: 'group',
    label: '人物',
    key: 'people',
    children: [{
      label: '叙事者',
      key: 'narrator'
      // icon: renderIcon(PersonIcon)
    }, {
      label: '羊男',
      key: 'sheep-man'
      // icon: renderIcon(PersonIcon)
    }]
  }, {
    label: '饮品',
    key: 'beverage',
    // icon: renderIcon(WineIcon),
    children: [{
      label: '威士忌',
      key: 'whisky'
    }]
  }, {
    label: '食物',
    key: 'food',
    children: [{
      label: '三明治',
      key: 'sandwich'
    }]
  }, {
    label: '过去增多，未来减少',
    key: 'the-past-increases-the-future-recedes'
  }]
}];
export default defineComponent({
  setup() {
    const selectedKey = 'homeIndex';
    return {
      inverted: ref(false),
      menuOptions,
      selectedKey
    };
  }
});