import { defineComponent, reactive, onMounted, watch, toRefs } from 'vue';
import { useStore } from 'vuex';
import Editor from "@tinymce/tinymce-vue";
import { rootUrl, apiUrl } from "@/api/";
export default defineComponent({
  components: {
    editor: Editor
  },
  props: {
    content: {
      type: String,
      default: ""
    }
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const lbEditor = reactive({
      src: process.env.BASE_URL + "plugin/tinymce/tinymce.min.js",
      model: "",
      init: {
        language: "zh-Hans",
        plugins: "preview searchreplace autolink directionality visualblocks visualchars image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help emoticons autosave code",
        toolbar: "code undo redo | forecolor backcolor bold italic underline strikethrough link | alignleft aligncenter alignright alignjustify outdent indent | bullist numlist | table image media ",
        branding: false,
        resize: false,
        height: "100%",
        automatic_uploads: false,
        autosave_ask_before_unload: true,
        convert_urls: false,
        file_picker_callback: (callback, value, meta) => {
          const rootUrl = lbEditor.rootUrl;
          const url = rootUrl + apiUrl("uploads");
          const fileTypes = {
            image: ".jpg, .jpeg, .png, .gif",
            media: ".mp3, .mp4",
            file: ".pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4"
          };
          const suffixes = fileTypes[meta.filetype];
          // 模拟出一个input用于添加本地文件
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", suffixes);
          input.click();
          input.onchange = e => {
            const tokenValid = Object.keys(store.getters.token).length > 0;
            if (tokenValid) {
              const xhr = new XMLHttpRequest();
              xhr.withCredentials = false;
              xhr.open("POST", url);
              const token = store.getters.token;
              xhr.setRequestHeader(token.name, token.value);
              xhr.onload = async () => {
                if (xhr.status !== 200) {
                  console.log("HTTP Error: " + xhr.status);
                  return;
                }
                const json = JSON.parse(xhr.responseText);
                if (!json || json.code !== 200) {
                  console.log(json);
                  return;
                }
                const path = rootUrl + json.data.webPath;
                callback(path);
              };
              const formData = new FormData();
              const files = input.files;
              const file = files[0];
              formData.append("file", file);
              xhr.send(formData);
            } else {
              console.log("error");
            }
          };
        }
      },
      rootUrl: "",
      getValue: () => {
        return lbEditor.model;
      }
    });
    onMounted(async () => {
      lbEditor.rootUrl = await rootUrl();
    });
    watch(() => props.content, data => {
      lbEditor.model = data;
    });
    return {
      ...toRefs(lbEditor)
    };
  }
});