const params = {
  token: "iebYpToken",
  user: "iebYpUser",
  areas: "iebYpAreas",
  // ind cy
  indc: "iebYpIndc",
  // ind hy
  indh: "iebYpIndh",
  // ind tyc
  indt: "iebYpIndt",
  // accounts
  accounts: "iebYpAccounts"
};
export default createStore({
  state: {
    iebYpToken: "" || localStorage.getItem("iebYpToken"),
    iebYpUser: "" || localStorage.getItem("iebYpUser"),
    iebYpAreas: "" || localStorage.getItem("iebYpAreas"),
    iebYpIndc: "" || localStorage.getItem("iebYpIndc"),
    iebYpIndh: "" || localStorage.getItem("iebYpIndh"),
    iebYpIndt: "" || localStorage.getItem("iebYpIndt"),
    iebYpAccounts: "" || localStorage.getItem("iebYpAccounts")
  },
  getters: {
    // token: (state: Record<any, any>) => state[params.token],
    token: state => {
      const token = !state[params.token] ? {} : JSON.parse(state[params.token]);
      // 超时清除刷新
      const time = Math.floor(new Date().getTime() / 1000);
      if (token.expire !== undefined && time > token.expire) {
        localStorage.setItem(params.token, JSON.stringify({}));
        window.location.reload();
      }
      return token;
    },
    user: state => !state[params.user] ? {} : JSON.parse(state[params.user]),
    areas: state => !state[params.areas] ? [] : JSON.parse(state[params.areas]),
    indc: state => !state[params.indc] ? [] : JSON.parse(state[params.indc]),
    indh: state => !state[params.indh] ? [] : JSON.parse(state[params.indh]),
    indt: state => !state[params.indt] ? [] : JSON.parse(state[params.indt]),
    // public key
    pkey: () => `
      -----BEGIN PUBLIC KEY-----
      MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCwWuMHwEnBAS5b5kHHWx6rMtC0fPHEbscQ1V16U7DPY7ttWyPiewsxS+dGY3zRfBUUIlQvj3d52VpJnQWlIUZird5bA1/IZjnfJa4xilXzGe7usxL74ABXhpf5pGzJP79Scrx4zeQME4sJ6Vhivf6XzfN7TEHgWwTOMrwrl31B1QIDAQAB
      -----END PUBLIC KEY-----
    `
  },
  mutations: {
    setD: (state, object) => {
      const datasType = ["[object Array]", "[object Object]"];
      // 字段处理
      const key = params[object.key];
      // 数据处理
      const value = object.value;
      const isDatas = datasType.indexOf(Object.prototype.toString.call(value)) >= 0;
      const data = isDatas ? JSON.stringify(value) : value;
      // 数据保存
      state[key] = data;
      localStorage.setItem(key, data);
    }
  },
  actions: {},
  modules: {}
});