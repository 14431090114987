import { defineComponent, reactive, computed, shallowRef, nextTick, toRefs } from 'vue';
import G6 from "@antv/g6";
export default defineComponent({
  components: {},
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  setup(props, {
    emit
  }) {
    // global
    const chart = reactive({
      // 数据准备
      ready: computed(() => {
        chart.draw();
        return Object.keys(props.data).length > 0;
      }),
      // 对象
      ref: null,
      // 实例
      chart: shallowRef(),
      // 配置
      opts: {
        container: null,
        width: 0,
        height: 500,
        modes: {
          default: [{
            type: "collapse-expand",
            onChange: function onChange(item, collapsed) {
              const data = item.get("model");
              data.collapsed = collapsed;
              return true;
            }
          }, "drag-canvas"
          // "zoom-canvas",
          ]
        },

        defaultNode: {
          type: "card-node",
          anchorPoints: [[0, 0.5], [1, 0.5]]
        },
        defaultEdge: {
          type: "polyline"
        },
        layout: {
          type: "mindmap",
          direction: "H",
          // getHeight: () => 60,
          // getWidth: () => 80,
          getVGap: () => 10,
          getHGap: () => 100,
          getSide: d => d.data.side === "left" ? "left" : "right"
        }
      },
      // 画
      draw: () => {
        nextTick(() => {
          chart.render();
        });
      },
      // 渲染
      render: () => {
        // base
        chart.opts.container = chart.ref;
        chart.opts.width = chart.ref.scrollWidth;
        // gragh
        chart.chart = new G6.TreeGraph(chart.opts);
        // node
        let centerX = 0;
        let centerY = 0;
        chart.chart.node(function (node) {
          if (node.id === "0") {
            centerX = node.x;
            centerY = node.y;
          }
          return {
            style: {
              fill: node.color,
              lineWidth: 0
            },
            label: node.label,
            labelCfg: {
              position: node.children && node.children.length > 0 ? "bottom" : node.x > centerX ? "right" : "left",
              offset: 5,
              style: {
                fill: "#fff"
              }
            }
          };
        });
        // data
        const data = JSON.parse(JSON.stringify(props.data));
        chart.chart.data(data);
        chart.chart.render();
        const cX = -centerX + chart.chart.getWidth() / 2;
        const cY = -centerY + chart.chart.getHeight() / 2;
        chart.chart.translate(cX, cY);
        chart.chart.on("node:click", e => {
          const item = e.item.get("model");
          if (item.collapsed === undefined) {
            // 末层节点
            emit("open", item.label);
          }
        });
      }
    });
    G6.registerNode("card-node", {
      draw: function drawShape(cfg, group) {
        const r = 2;
        const color = "rgba(246, 246, 246, 1)";
        const nodeType = cfg.id.length === 1 && cfg.id !== "0" ? "type" : "company";
        const w = nodeType === "type" ? 60 : 300;
        const h = nodeType === "type" ? 24 : 32;
        const shape = group.addShape("rect", {
          attrs: {
            x: -w / 2,
            y: -h / 2,
            width: w,
            height: h,
            stroke: color,
            radius: r,
            fill: color
          },
          name: "main-box",
          draggable: true
        });
        if (nodeType === "type") {
          group.addShape("rect", {
            attrs: {
              x: -w / 2,
              y: -h / 2,
              width: w,
              height: h,
              fill: cfg.color,
              radius: r
            },
            name: "title-box",
            draggable: true
          });
          group.addShape("text", {
            attrs: {
              y: (h - 12) / 2,
              text: cfg.label,
              fontSize: 12,
              textAlign: "center",
              fill: "#fff"
            },
            name: "title"
          });
        } else {
          group.addShape("rect", {
            attrs: {
              x: -w / 2,
              y: -h / 2,
              width: h,
              height: h,
              fill: cfg.color,
              radius: r
            },
            name: "title-box",
            draggable: true
          });
          group.addShape("text", {
            attrs: {
              x: -w / 2 + h / 2,
              y: (h - 12) / 2,
              text: cfg.score ? cfg.score : "…",
              fontSize: 16,
              textAlign: "center",
              fill: "#FFF"
            },
            name: "title"
          });
          group.addShape("text", {
            attrs: {
              x: -w / 2 + h + 5,
              y: (h - 16) / 2,
              text: cfg.label,
              fontSize: 14,
              fill: "#000"
            },
            name: "title"
          });
        }
        return shape;
      },
      setState(name, value, item) {
        if (name === "collapsed") {
          const marker = item.get("group").find(ele => ele.get("name") === "collapse-icon");
          const icon = value ? G6.Marker.expand : G6.Marker.collapse;
          marker.attr("symbol", icon);
        }
      }
    });
    return {
      ...toRefs(chart)
    };
  }
});