import { defineComponent, reactive, computed, onMounted, toRefs } from 'vue';
import { useStore } from 'vuex';
import "core-js/modules/es.array.push.js";
import { getAreasByLevel } from "@/util/area";
import { getIndHs } from "@/util/industry";
import { getAreaName } from "@/util/area";
import { getIndHName } from "@/util/industry";
export default defineComponent({
  components: {},
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    },
    preset: {
      type: Object,
      default: {}
    },
    data: {
      type: Object,
      default: {}
    }
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const as = reactive({
      ref: null,
      formStyle: {
        maxWidth: "1200px",
        paddingRight: "20px"
      },
      lw: 100,
      // 已选参数
      params: [],
      // 所有筛选条件 type name label placeholder min max value options children
      filterAll: [{
        type: "area",
        name: "code",
        label: "地区选择",
        placeholder: "请选择地区",
        value: "100000",
        options: computed(() => {
          return store.getters.areas.length > 0 ? getAreasByLevel("d") : [];
        })
      }, {
        type: "industry",
        name: "industry",
        label: "行业选择",
        placeholder: "请选择行业",
        value: 0,
        options: computed(() => {
          return store.getters.indh.length > 0 ? getIndHs() : [];
        })
      }, {
        type: "input",
        name: "keyword",
        label: "关键字",
        placeholder: "请输入查询关键字",
        value: ""
      }, {
        type: "radio",
        name: "capitalR",
        label: "注册资金",
        value: 0,
        options: [{
          label: "全部",
          value: 0
        }, {
          label: "100万内",
          value: "0--100"
        }, {
          label: "100万-200万",
          value: "100--200"
        }, {
          label: "200万-500万",
          value: "200--500"
        }, {
          label: "500万-1000万",
          value: "500--1000"
        }, {
          label: "1000万以上",
          value: "1000--"
        }]
      }, {
        type: "radio",
        name: "date",
        label: "成立时间",
        value: 0,
        options: [{
          label: "全部",
          value: 0
        },
        // { label: "1年内", value: "1|0" },
        {
          label: "5年内",
          value: "5--0"
        }, {
          label: "5-10年",
          value: "10--5"
        }, {
          label: "10-15年",
          value: "15--10"
        }, {
          label: "15年以上",
          value: "--15"
        }]
      },
      // {
      //   type: "checkbox",
      //   name: "checkboxkey",
      //   label: "多选条件",
      //   value: [] as (string | number)[] | null,
      //   options: [
      //     { label: "选项1", value: "1" },
      //     { label: "选项2", value: "2" },
      //     { label: "选项3", value: "3" },
      //     { label: "选项4", value: "4" },
      //     { label: "选项5", value: "5" },
      //     { label: "选项6", value: "6" },
      //   ],
      // },
      {
        type: "slider",
        name: "score",
        label: "评分区间",
        max: 100,
        value: [0, 100]
        // options: {
        //   0: 0,
        //   4: 4,
        //   6: 6,
        //   10: 10,
        // },
      }, {
        type: "popselects",
        name: "popselect",
        label: "更多筛选",
        children: [{
          name: "param1",
          value: 0,
          options: [{
            label: "参数1所有",
            value: 0
          }, {
            label: "参数1选项1",
            value: "1"
          }, {
            label: "参数1选项2",
            value: "2"
          }, {
            label: "参数1选项3",
            value: "3"
          }, {
            label: "参数1选项4",
            value: "4"
          }, {
            label: "参数1选项5",
            value: "5"
          }, {
            label: "参数1选项6",
            value: "6"
          }, {
            label: "参数1选项7",
            value: "7"
          }, {
            label: "参数1选项8",
            value: "8"
          }, {
            label: "参数1选项9",
            value: "9"
          }, {
            label: "参数1选项10",
            value: "10"
          }, {
            label: "参数1选项11",
            value: "11"
          }, {
            label: "参数1选项12",
            value: "12"
          }, {
            label: "参数1选项13",
            value: "13"
          }, {
            label: "参数1选项14",
            value: "14"
          }, {
            label: "参数1选项15",
            value: "15"
          }, {
            label: "参数1选项16",
            value: "16"
          }, {
            label: "参数1选项17",
            value: "17"
          }]
        }, {
          name: "param2",
          value: 0,
          options: [{
            label: "参数2所有",
            value: 0
          }, {
            label: "参数2选项1",
            value: "1"
          }, {
            label: "参数2选项2",
            value: "2"
          }]
        }]
      }],
      // 筛选条件
      filters: [],
      // 绑定数据
      models: {},
      // 初始化
      init: (clear = false) => {
        const data = clear ? props.preset : props.data;
        const dataKeys = Object.keys(data);
        const filters = [];
        as.filterAll.forEach(f => {
          // 更多参数判断
          if (f.type === "popselects") {
            let psNumber = 0;
            f.children.forEach(fc => {
              if (dataKeys.indexOf(fc.name) >= 0) {
                psNumber++;
              }
            });
            if (psNumber > 0) {
              filters.push(f);
            }
          }
          // 常规参数判断
          else {
            if (dataKeys.indexOf(f.name) >= 0) {
              filters.push(f);
            }
          }
          // if (f.type === "popselects") {
          //   f.children.forEach((fc: Record<any, any>) => {
          //     as.models[fc.name] =
          //       data[fc.name] !== undefined
          //         ? data[fc.name]
          //         : fc.options[0].value;
          //   });
          // } else if (
          //   ((f.type === "area" || f.type === "industry") &&
          //     data[f.name] !== undefined) ||
          //   (f.type !== "area" && f.type !== "industry")
          // ) {
          //   as.models[f.name] =
          //     data[f.name] !== undefined ? data[f.name] : f.value;
          // }
        });

        as.filters = filters;
        as.filters.forEach(f => {
          if (f.type === "popselects") {
            f.children.forEach(fc => {
              as.models[fc.name] = data[fc.name] !== undefined ? data[fc.name] : fc.options[0].value;
            });
          } else {
            as.models[f.name] = data[f.name] !== undefined ? data[f.name] : f.value;
          }
        });
      },
      // 行业改变
      industryUpdate: (value, name) => {
        if (value === null) {
          as.models[name] = 0;
        }
        as.update();
      },
      // 更多参数改变
      popselectUpdate: (value, name) => {
        as.models[name] = value;
        as.update();
      },
      // 值变化
      update: () => {
        const params = [];
        as.filters.forEach(f => {
          const value = as.models[f.name];
          if (f.type === "popselects") {
            f.children.forEach(fc => {
              const psValue = as.models[fc.name];
              if (JSON.stringify(fc.options[0].value) != JSON.stringify(psValue)) {
                const psOption = fc.options.find(o => o.value === psValue);
                const psValueStr = psOption ? psOption.label : "";
                params.push({
                  type: f.type,
                  name: fc.name,
                  value: psValue,
                  valueStr: psValueStr
                });
              }
            });
          } else {
            if (f.type === "area" || f.type === "industry" || JSON.stringify(f.value) != JSON.stringify(value)) {
              let valueStr = "";
              if (f.type === "area") {
                valueStr = getAreaName(value);
              } else if (f.type === "industry") {
                valueStr = getIndHName(value);
              } else if (f.type === "input") {
                valueStr = value;
              } else if (f.type === "radio") {
                const option = f.options.find(o => o.value === value);
                if (option) {
                  valueStr = option.label;
                }
              } else if (f.type === "checkbox") {
                const valueStrs = [];
                value.forEach(v => {
                  const option = f.options.find(o => o.value === v);
                  if (option) {
                    valueStrs.push(option.label);
                  }
                });
                valueStr = valueStrs.join("、");
              } else if (f.type === "slider") {
                const valueStart = f.options && f.options[value[0]] ? f.options[value[0]] : value[0];
                const valueEnd = f.options && f.options[value[1]] ? f.options[value[1]] : value[1];
                valueStr = valueStart + "-" + valueEnd;
              }
              const hide = (f.type === "area" || f.type === "industry") && JSON.stringify(f.value) == JSON.stringify(value);
              params.push({
                name: f.name,
                value: value,
                valueStr: f.label + "：" + valueStr,
                hide: hide
              });
            }
          }
        });
        as.params = params;
      },
      // 移除条件
      remove: p => {
        const filter = as.filters.find(f => f.name === p.name);
        if (filter) {
          as.models[p.name] = filter.value;
        } else if (p.type === "popselects") {
          const psFilter = as.filters.find(f => f.type === p.type);
          if (psFilter && psFilter.children) {
            const ps = psFilter.children.find(fc => fc.name === p.name);
            if (ps) {
              as.models[p.name] = ps.options[0].value;
            }
          }
        }
        as.update();
      },
      // 重置条件
      reset: () => {
        if (props.clearable) {
          emit("clear");
        }
        as.init(true);
        as.update();
      },
      // 搜索
      search: () => {
        const params = [];
        as.params.forEach(p => {
          params.push({
            name: p.name,
            value: p.value,
            valueStr: p.valueStr
          });
        });
        emit("search", params);
      }
    });
    onMounted(() => {
      as.init();
      as.update();
    });
    return {
      ...toRefs(as)
    };
  }
});