import { defineComponent, reactive, watch, toRefs } from 'vue';
export default defineComponent({
  components: {},
  props: {
    btns: {
      type: Array,
      default: []
    },
    forms: {
      type: Array,
      default: []
    }
  },
  setup(props, {
    emit
  }) {
    const filter = reactive({
      ref: null,
      // 绑定数据
      models: {},
      // 渲染
      render: () => {
        props.forms.forEach(f => {
          filter.models[f.name] = f.value;
        });
      },
      // 按钮过滤
      bF: e => {
        emit("b-f", e.key);
      },
      // 条件过滤
      fF: () => {
        const formData = {
          ...filter.models
        };
        emit("f-f", formData);
      }
    });
    watch(() => [props.btns, props.forms], () => {
      filter.render();
    });
    return {
      ...toRefs(filter)
    };
  }
});