import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_n_button = _resolveComponent("n-button");
  const _component_n_space = _resolveComponent("n-space");
  const _component_n_input = _resolveComponent("n-input");
  const _component_n_select = _resolveComponent("n-select");
  const _component_n_tree_select = _resolveComponent("n-tree-select");
  const _component_n_cascader = _resolveComponent("n-cascader");
  const _component_n_form_item = _resolveComponent("n-form-item");
  const _component_n_form = _resolveComponent("n-form");
  return _openBlock(), _createBlock(_component_n_space, {
    vertical: false
  }, {
    default: _withCtx(() => [_ctx.btns.length > 0 ? (_openBlock(), _createBlock(_component_n_space, {
      key: 0
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.btns, b => {
        return _openBlock(), _createBlock(_component_n_button, {
          type: b.type,
          ghost: !b.current,
          onClick: $event => _ctx.bF(b)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(b.label), 1)]),
          _: 2
        }, 1032, ["type", "ghost", "onClick"]);
      }), 256))]),
      _: 1
    })) : _createCommentVNode("", true), _ctx.forms.length > 0 ? (_openBlock(), _createBlock(_component_n_form, {
      key: 1,
      ref: "ref",
      "label-placement": "left",
      model: _ctx.models
    }, {
      default: _withCtx(() => [_createVNode(_component_n_space, null, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.forms, f => {
          return _openBlock(), _createBlock(_component_n_form_item, {
            "show-feedback": false,
            path: f.name
          }, {
            default: _withCtx(() => [f.type === 'input' ? (_openBlock(), _createBlock(_component_n_input, {
              key: 0,
              clearable: "",
              value: _ctx.models[f.name],
              "onUpdate:value": $event => _ctx.models[f.name] = $event,
              placeholder: f.placeholder
            }, null, 8, ["value", "onUpdate:value", "placeholder"])) : f.type === 'select' ? (_openBlock(), _createBlock(_component_n_select, {
              key: 1,
              clearable: "",
              filterable: "",
              options: f.options,
              value: _ctx.models[f.name],
              "onUpdate:value": $event => _ctx.models[f.name] = $event,
              placeholder: f.placeholder
            }, null, 8, ["options", "value", "onUpdate:value", "placeholder"])) : f.type === 'select-tree' ? (_openBlock(), _createBlock(_component_n_tree_select, {
              key: 2,
              clearable: "",
              filterable: "",
              "default-expand-all": "",
              options: f.options,
              value: _ctx.models[f.name],
              "onUpdate:value": $event => _ctx.models[f.name] = $event,
              placeholder: f.placeholder,
              style: {
                minWidth: '200px'
              }
            }, null, 8, ["options", "value", "onUpdate:value", "placeholder"])) : f.type === 'area' ? (_openBlock(), _createBlock(_component_n_cascader, {
              key: 3,
              clearable: "",
              filterable: "",
              "expand-trigger": "hover",
              "value-field": "code",
              value: _ctx.models[f.name],
              "onUpdate:value": $event => _ctx.models[f.name] = $event,
              placeholder: f.placeholder,
              options: f.options
            }, null, 8, ["value", "onUpdate:value", "placeholder", "options"])) : _createCommentVNode("", true)]),
            _: 2
          }, 1032, ["path"]);
        }), 256)), _createVNode(_component_n_form_item, {
          "show-feedback": false
        }, {
          default: _withCtx(() => [_createVNode(_component_n_button, {
            onClick: _ctx.fF
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }, 8, ["onClick"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }, 8, ["model"])) : _createCommentVNode("", true)]),
    _: 1
  });
}