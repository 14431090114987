import { defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { zhCN, dateZhCN } from "naive-ui";
import AMapLoader from "@amap/amap-jsapi-loader";
import { lFrame } from "@/component/";
export default defineComponent({
  components: {
    lFrame
  },
  setup() {
    const store = useStore();
    // naive UI 语言、自定义主题参数
    const initialize = reactive({
      zhCN,
      dateZhCN,
      themeOverrides: {}
    });
    fetch(`${process.env.BASE_URL}static/theme/naive-ui-theme-overrides.json`).then(res => res.json()).then(data => {
      initialize.themeOverrides = data;
    });
    // amap 高德地图
    const amap = reactive({
      // 预加载
      preload: () => {
        AMapLoader.load({
          key: "26e1bf4fec61714cef22442fd803fbcb",
          version: "2.0",
          plugins: [
          // 城市查询
          "AMap.CitySearch", "AMap.DistrictSearch",
          // 工具
          "AMap.ToolBar", "AMap.ControlBar", "AMap.ElasticMarker",
          // 热力
          "AMap.HeatMap", "AMap.PlaceSearch"],
          AMapUI: {
            plugins: ["overlay/SimpleMarker", "misc/PositionPicker"]
          },
          Loca: {
            version: "2.0.0"
          }
        }).then(() => {
          console.log("AMap Ready");
        }).catch(e => {
          console.log(e);
        });
      }
    });
    amap.preload();
    // 地区编码
    // area
    const area = reactive({
      preload: () => {
        if (store.getters.areas.length === 0) {
          fetch(`${process.env.BASE_URL}static/data/district.json`).then(res => res.json()).then(data => {
            store.commit("setD", {
              key: "areas",
              value: data
            });
          });
        }
        // else {
        //   store.commit("setD", { key: "areas", value: [] });
        //   console.log("de");
        // }
      }
    });

    area.preload();
    // 行业h
    // indh
    const indh = reactive({
      preload: () => {
        if (store.getters.indh.length === 0) {
          fetch(`${process.env.BASE_URL}static/data/industryh.json`).then(res => res.json()).then(data => {
            store.commit("setD", {
              key: "indh",
              value: data
            });
          });
        }
      }
    });
    indh.preload();
    // 行业t
    // indt
    const indt = reactive({
      preload: () => {
        if (store.getters.indt.length === 0) {
          fetch(`${process.env.BASE_URL}static/data/industryt.json`).then(res => res.json()).then(data => {
            store.commit("setD", {
              key: "indt",
              value: data
            });
          });
        }
      }
    });
    indt.preload();
    return {
      ...toRefs(initialize)
    };
  }
});