import { defineComponent, reactive, computed, shallowRef, nextTick, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
import * as echarts from "echarts";
import { ckType } from "@/util/base";
export default defineComponent({
  components: {},
  props: {
    textColor: {
      type: String,
      default: "blk"
    },
    direction: {
      type: String,
      default: "horizontal"
    },
    legend: {
      type: Boolean,
      default: true
    },
    coaxial: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: "item"
    },
    visual: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    datas: {
      type: Array,
      default: []
    },
    crtImg: {
      type: Boolean,
      default: false
    },
    kAxis: {
      type: Boolean,
      default: true
    },
    dataZoom: {
      type: Boolean,
      default: false
    },
    grid: {
      type: Object,
      default: {}
    },
    keyFontSize: {
      type: Number,
      default: 12
    }
  },
  setup(props) {
    const tool = reactive({
      lg: (direction, colors) => {
        const k1 = direction === "rl" ? 1 : 0;
        const k2 = direction === "bt" ? 1 : 0;
        const k3 = direction === "lr" ? 1 : 0;
        const k4 = direction === "tb" ? 1 : 0;
        const interval = colors.length - 1 > 0 ? 1 / (colors.length - 1) : 0;
        const colorBox = [];
        colors.forEach((c, idx) => {
          colorBox.push({
            offset: c[1] ? c[1] : idx * interval,
            color: c[0]
          });
        });
        return new echarts.graphic.LinearGradient(k1, k2, k3, k4, colorBox);
      }
    });
    // colors
    const colors = reactive({
      text: {
        blk: "rgba(0, 0, 0, 1)",
        wht: "rgba(255, 255, 255, 1)"
      }
    });
    // global
    const chart = reactive({
      // 数据准备
      ready: computed(() => {
        chart.draw();
        return props.datas.length > 0;
      }),
      // 对象
      ref: null,
      // 实例
      chart: shallowRef(),
      // 配置
      opts: {
        grid: {
          left: 10,
          right: 10,
          top: 60,
          bottom: 10,
          containLabel: true
        },
        title: {
          text: props.title,
          textStyle: {
            fontWeight: "normal",
            color: colors.text[props.textColor]
          },
          subtext: props.subtitle
        },
        tooltip: {
          show: true,
          trigger: props.tooltip
        },
        legend: {
          show: props.legend,
          textStyle: {
            color: colors.text[props.textColor]
          }
        },
        visualMap: props.visual ? {
          show: false,
          min: 0,
          inRange: {}
        } : undefined,
        dataZoom: props.dataZoom ? [{}] : false,
        xAxis: [],
        yAxis: [],
        series: []
      },
      // 画
      draw: () => {
        nextTick(() => {
          chart.process();
          chart.render();
        });
      },
      // 数据处理
      process: () => {
        const keys = {
          show: props.kAxis,
          data: [],
          axisLabel: {
            color: colors.text[props.textColor],
            fontSize: props.keyFontSize
          }
        };
        let values = [];
        const serieses = [];
        props.datas.forEach((c, idx) => {
          // 颜色
          if (c.color) {
            if (chart.opts.color === undefined) {
              chart.opts.color = [];
            }
            const cColorType = ckType(c.color);
            if (cColorType === "string") {
              chart.opts.color.push(`rgba(${c.color}, 1)`);
            } else if (cColorType === "array" && c.color.length === 1) {
              chart.opts.color.push(`rgba(${c.color[0]}, 1)`);
            } else if (cColorType === "array" && c.color.length === 2) {
              chart.opts.color.push(tool.lg("bt", [[`rgba(${c.color[0]}, 1)`], [`rgba(${c.color[1]}, 1)`]]));
            }
            // chart.opts.color.push(`rgba(${c.color}, 1)`);
          } else if (c.colors) {
            chart.opts.color = c.colors;
          }
          // 值轴
          if (props.coaxial) {
            if (values.length === 0) {
              values = chart.setV(c);
            }
          } else {
            values.push(chart.setV(c));
          }
          // 数据
          const datas = [];
          c.datas.forEach(i => {
            // 类目轴
            if (idx === 0) {
              keys.data.push(i.label);
            }
            const data = {
              name: i.label,
              value: i.value
            };
            if (i.extend !== undefined) {
              data.extend = i.extend;
            }
            if (c.colors) {
              data.label = {
                color: c.colors[datas.length % c.colors.length]
              };
            }
            if (c.label && c.label.position) {
              if (data.label === undefined) {
                data.label = {};
              }
              data.label.position = c.label.position(data.value);
            }
            datas.push(data);
          });
          serieses.push(chart.setS(c, datas, idx));
        });
        if (props.direction === "horizontal") {
          chart.opts.xAxis = keys;
          chart.opts.yAxis = values;
        } else {
          chart.opts.xAxis = values;
          chart.opts.yAxis = keys;
        }
        chart.opts.series = serieses;
        // grid
        if (Object.keys(props.grid).length > 0) {
          const grid = props.grid;
          if (grid.top !== undefined) {
            chart.opts.grid.top = grid.top;
          }
          if (grid.bottom !== undefined) {
            chart.opts.grid.bottom = grid.bottom;
          }
          if (grid.left !== undefined) {
            chart.opts.grid.left = grid.left;
          }
          if (grid.right !== undefined) {
            chart.opts.grid.right = grid.right;
          }
        }
      },
      // 设置值轴
      setV: c => {
        const name = props.coaxial ? c.title : c.name;
        const vAlign = c.vAlign ? c.vAlign : "left";
        const unit = c.unit ? c.unit : "";
        const alignTicks = c.alignTicks ? c.alignTicks : false;
        const v = {
          // show: c.vShow === false ? false : true,
          name: c.vShow === false ? "" : name,
          nameTextStyle: {
            align: vAlign,
            color: colors.text[props.textColor]
          },
          type: "value",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: c.vShow === false ? false : true,
            formatter: `{value}${unit}`,
            color: colors.text[props.textColor]
          },
          splitLine: {
            show: c.split || c.split === undefined,
            lineStyle: {
              opacity: c.splitOpacity ? c.splitOpacity : 0.05
            }
          },
          alignTicks: alignTicks
        };
        if (c.coaxial0) {
          v.max = value => {
            const max = Math.abs(value.max) > Math.abs(value.min) ? (Math.abs(value.max) * 1.5).toFixed(2) : (Math.abs(value.min) * 1.5).toFixed(2);
            return isNaN(parseFloat(max)) ? 1 : max;
          };
          v.min = value => {
            const min = Math.abs(value.max) > Math.abs(value.min) ? (-Math.abs(value.max) * 1.5).toFixed(2) : (-Math.abs(value.min) * 1.5).toFixed(2);
            return isNaN(parseFloat(min)) ? -1 : min;
          };
        }
        if (c.max !== undefined) {
          v.max = c.max;
        }
        if (c.min !== undefined) {
          v.min = c.min;
        }
        return v;
      },
      // 设置series
      setS: (c, datas, yIndex) => {
        // 类型映射
        const types = {
          line: "line",
          lineArea: "line",
          lineArea2: "line",
          bar: "bar",
          cellBar: "bar",
          pictorialBar: "pictorialBar",
          humpBar: "pictorialBar",
          pie: "pie"
        };
        // series
        const unit = c.unit ? c.unit : "";
        // const formatter = (i: any) => {
        //   const item = c.datas[i.dataIndex];
        //   const extend = item.extend ? " " + item.extend : "";
        //   const explain = item.explain ? "<br />" + item.explain : "";
        //   return `
        //     <div style="max-width:200px">
        //       ${i.seriesName}
        //       <br />${i.marker}${i.name}：${i.value}${unit}${extend}
        //       <span style="white-space:normal">${explain}</span>
        //     </div>
        //     `;
        // };
        const series = {
          name: c.name,
          type: types[c.type],
          data: datas,
          label: {
            formatter: i => {
              return `${i.data.value}${unit}`;
              // return i.data.value > 0 ? `${i.data.value}${unit}` : "";
            }
          },

          tooltip: {
            formatter: i => {
              const item = c.datas[i.dataIndex];
              const explain = item.explain ? "<br />" + item.explain : "";
              return `
                <div style="max-width:200px">
                  ${i.seriesName}
                  <br />${i.marker}${i.name}：${i.value}${unit}
                  <span style="white-space:normal">${explain}</span>
                </div>
                `;
            }
            // valueFormatter: (i: any) => i + unit,
          }
        };

        if (props.coaxial === false) {
          series.yAxisIndex = yIndex;
        }
        // 柱图
        if (c.type === "bar") {
          // 柱宽
          series.barWidth = c.width ? c.width : "50%";
          series.showBackground = c.bg;
          series.itemStyle = {
            borderRadius: c.radius ? c.radius : 0,
            color: i => {
              let color = i.color;
              const dataColor = c.datas[i.dataIndex].color;
              if (dataColor) {
                const colorType = ckType(dataColor);
                if (colorType === "string") {
                  color = `rgba(${dataColor}, 1)`;
                } else if (colorType === "array" && dataColor.length === 1) {
                  color = `rgba(${dataColor[0]}, 1)`;
                } else if (colorType === "array" && dataColor.length === 2) {
                  color = tool.lg("bt", [[`rgba(${dataColor[0]}, 1)`], [`rgba(${dataColor[1]}, 1)`]]);
                }
              }
              return color;
            }
          };
          series.label.show = c.label && c.label.show !== undefined ? c.label.show : true;
          series.label.position = "top";
          series.label.color = colors.text[props.textColor];
          if (c.emphasis) {
            series.emphasis = c.emphasis;
          }
          series.animation = c.animation !== undefined ? c.animation : true;
        }
        // 饼图
        else if (c.type === "pie") {
          if (c.left !== undefined) {
            series.left = c.left;
          }
          if (c.right !== undefined) {
            series.right = c.right;
          }
          if (c.top !== undefined) {
            series.top = c.top;
          }
          if (c.bottom !== undefined) {
            series.bottom = c.bottom;
          }
          if (c.clockwise !== undefined) {
            series.clockwise = c.clockwise;
          }
          if (c.radius !== undefined) {
            series.radius = c.radius;
          }
          series.label = {
            formatter: `{b}:{c}${unit}`
          };
          if (c.border !== false) {
            series.itemStyle = {
              borderColor: "rgba(255, 255, 255, 1)",
              borderWidth: 2
            };
          }
        }
        // 视觉
        if (props.visual === true) {
          if (c.max) {
            chart.opts.visualMap.max = Math.max(chart.opts.visualMap.max ? chart.opts.visualMap.max : 0, c.max);
          }
          chart.opts.visualMap.inRange.color = [`rgba(${c.color}, 0.6)`, `rgba(${c.color}, 1)`];
        }
        if (props.tooltip === "axis") {
          series.tooltip = {
            valueFormatter: i => {
              const item = datas.find(d => d.value === i);
              return item && item.extend ? `${i} ${item.extend}` : i;
            }
          };
        }
        return series;
      },
      // 渲染
      render: () => {
        // 已渲染
        if (chart.ref && chart.chart) {
          chart.chart.setOption(chart.opts, true);
          chart.rerender();
        } else {
          chart.chart = echarts.init(chart.ref);
          setTimeout(() => {
            chart.chart.setOption(chart.opts);
          }, 800);
          // window resize
          window.addEventListener("resize", () => {
            chart.rerender();
          });
          // box resize
          new ResizeObserver(() => {
            chart.rerender();
          }).observe(chart.ref);
          // finish
          chart.chart.on("finished", () => {
            // finished
            setTimeout(() => {
              if (props.crtImg && chart.img === "") {
                chart.img = chart.chart.getDataURL();
              }
            }, 2000);
          });
        }
      },
      // 重新渲染
      rerender: () => {
        chart.chart.resize(chart.ref);
      },
      // 销毁
      destroy: () => {
        chart.chart.dispose();
      },
      // 图片
      img: "",
      // 获取图片
      getImg: async () => {
        return new Promise(resolve => {
          const chartImg = setInterval(() => {
            if (chart.img) {
              clearInterval(chartImg);
              resolve(chart.img);
            }
          }, 100);
        });
      }
    });
    return {
      ...toRefs(chart)
    };
  }
});