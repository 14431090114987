import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7d8b2970"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pie-gauge"
};
const _hoisted_2 = {
  class: "pie-gauge-box",
  ref: "pieRef"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, null, 512)]);
}