import { defineComponent, getCurrentInstance, reactive, h, computed, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
import { NA, NAvatar, NButton, NEllipsis, NIcon, NSpace, NTag } from "naive-ui";
import uDesc from "./desc.vue";
export default defineComponent({
  components: {
    uDesc
  },
  props: {
    remote: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "small"
    },
    height: {
      type: Number,
      default: 500
    },
    rowKey: {
      type: String,
      default: "key"
    },
    pag: {
      type: Object,
      default: {}
    },
    thead: {
      type: Array,
      default: []
    },
    datas: {
      type: Array,
      default: []
    }
  },
  setup(props, {
    emit
  }) {
    const mc = getCurrentInstance()?.appContext.config.globalProperties.$mc;
    const preset = reactive({
      renderIcon: (param, value, style = null) => {
        const icons = {
          sort: {
            default: "default",
            ascend: "asc",
            descend: "desc"
          }
        };
        const target = value === false ? "default" : value;
        const icon = icons[param][target];
        return h(NIcon, {
          style
        }, {
          default: () => h("i", {
            style: {
              fontSize: "14px"
            },
            class: `if i-${param}-${icon}`
          })
        });
      },
      page: 1
    });
    const table = reactive({
      width: 0,
      columns: computed(() => {
        table.width = 0;
        const columns = [];
        props.thead.forEach(ci => {
          // 展开
          if (ci.type === "expand") {
            ci.width = 40;
            ci.minWidth = 40;
            ci.renderExpand = row => {
              const expands = [];
              ci.expands.forEach(eI => {
                const e = {
                  ...eI
                };
                e.value = row[e.key];
                expands.push(e);
              });
              const ec = ci.expandColumn ? ci.expandColumn : 4;
              return h(uDesc, {
                column: ec,
                placement: "top",
                datas: expands
              });
            };
          }
          // 绑定操作
          if (ci.handle) {
            const handle = ci.handle;
            // 排序
            if (handle.sorter) {
              // 多列排序
              if (handle.sorter.multiple) {
                const key = ci.key;
                handle.sorter.compare = (a, b) => {
                  const ak = a[key],
                    bk = b[key],
                    af = isFinite(ak),
                    bf = isFinite(bk);
                  return af != bf && bf - af || (af && bf ? ak - bk : ak.localeCompare(bk));
                };
              }
              ci.sorter = handle.sorter;
              ci.renderSorterIcon = ({
                order
              }) => {
                // const style = { transform: "translateY(-3px)" };
                const style = {};
                return preset.renderIcon("sort", order, style);
              };
            }
            // 过滤
            if (handle.filter) {
              ci.filter = "default";
              ci.filterOptionValue = null;
              // 过滤条件
              const filters = [];
              handle.filter.forEach(fi => {
                filters.push({
                  label: fi,
                  value: fi
                });
              });
              // 清除过滤
              filters.push({
                label: "清除",
                value: null
              });
              ci.renderFilterMenu = ({
                hide
              }) => {
                // 过滤按钮
                const buttons = [];
                filters.forEach(fsi => {
                  const button = h(NButton, {
                    block: true,
                    size: "small",
                    color: ci.filterOptionValue !== null && ci.filterOptionValue === fsi.value ? `rgba(${mc}, 1)` : "",
                    onClick: () => {
                      ci.filterOptionValue = fsi.value;
                      hide();
                      const filterParam = {
                        key: ci.key,
                        value: ci.filterOptionValue
                      };
                      emit("filter", filterParam);
                    }
                  }, {
                    default: () => fsi.label
                  });
                  buttons.push(button);
                });
                return h(NSpace, {
                  style: {
                    padding: "10px"
                  },
                  vertical: true
                }, {
                  default: () => buttons
                });
              };
            }
            // 渲染
            if (handle.render) {
              ci.render = row => {
                let element = row[ci.key];
                handle.render.forEach(hi => {
                  let elementP = element;
                  if (hi.tmpl === "link") {
                    const ellipsis = h(NEllipsis, null, {
                      default: () => elementP
                    });
                    element = h(NA, {
                      class: "a-u",
                      onClick: () => hi.click ? hi.click(elementP, row) : false
                    }, {
                      default: () => ellipsis
                    });
                  } else if (hi.tmpl === "link-avatar") {
                    const avatar = elementP.slice(0, 2);
                    element = h(NSpace, {
                      align: "center",
                      wrap: false,
                      wrapItem: false
                    }, {
                      default: () => [h(NAvatar, {
                        style: {
                          flexShrink: 0
                        }
                      }, {
                        default: () => avatar
                      }), h(NA, {
                        class: "a-u",
                        onClick: () => hi.click ? hi.click(elementP) : false
                      }, {
                        default: () => elementP
                      })]
                    });
                  } else if (hi.tmpl === "icon") {
                    const size = hi.size ? hi.size : 14;
                    const color = hi.color ? hi.color : "";
                    element = h(NIcon, {
                      size: size,
                      color: color,
                      style: {
                        cursor: hi.click ? "pointer" : "default"
                      },
                      onClick: () => hi.click ? hi.click(elementP) : false
                    }, {
                      default: () => h("i", {
                        style: {
                          fontSize: size + "px"
                        },
                        class: `if i-${hi.name ? hi.name : elementP}`
                      })
                    });
                  } else if (hi.tmpl === "replace") {
                    element = hi.r(elementP, row);
                  } else if (hi.tmpl === "suffix") {
                    element = h("span", {}, {
                      default: () => elementP ? elementP + hi.suffix : ""
                    });
                  } else if (hi.tmpl === "status") {
                    const text = elementP ? "启用" : "停用";
                    const type = elementP ? "success" : "warning";
                    element = h(NTag, {
                      type: type
                    }, {
                      default: () => text
                    });
                  }
                });
                return element;
              };
            }
          }
          // 最小宽度
          if (ci.minWidth === undefined) {
            if (ci.key === "key" && ci.width === undefined) {
              ci.width = 60;
              ci.minWidth = 60;
            } else {
              ci.minWidth = ci.width ? ci.width : 100;
            }
          }
          const cWidth = ci.maxWidth ? ci.maxWidth : ci.minWidth;
          table.width += cWidth;
          columns.push(ci);
        });
        return columns;
      }),
      pagination: computed(() => {
        const pag = props.pag;
        const pagination = pag && props.remote ? {
          size: "small",
          page: pag.page ? pag.page : 1,
          pageSize: pag.size ? pag.size : 10,
          itemCount: pag.total ? pag.total : props.datas.length,
          prefix: info => {
            return `共计 ${info.itemCount} 条`;
          }
        } : {
          size: "small",
          pageSize: pag.size ? pag.size : 10,
          itemCount: pag.total ? pag.total : props.datas.length,
          prefix: info => {
            return `共计 ${info.itemCount} 条`;
          }
        };
        return pagination;
      }),
      change: page => {
        if (props.pag.change) {
          props.pag.change(page);
        }
      }
    });
    return {
      ...toRefs(table)
    };
  }
});