import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import routeList from "./config";
import store from "../store";

const loginPage = "login";

const routes: Array<RouteRecordRaw> = routeList;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const titleStrs = ["用户管理平台"];
  if (to.meta && to.meta.title) {
    titleStrs.push(to.meta.title as string);
  }
  titleStrs.reverse();
  document.title = titleStrs.join(" - ");
  // token有效
  const tokenValid = Object.keys(store.getters.token).length > 0;
  // 需要登录验证
  const loggedAuth = to.matched.some(
    (r) => r.meta.loggedAuth || r.meta.loggedAuth === undefined
  );
  let n = null;
  if (loggedAuth && !tokenValid) {
    n = { name: loginPage, query: { redirect: to.fullPath } };
  } else if (
    !loggedAuth &&
    to.name !== "home" &&
    (tokenValid || to.name === undefined)
  ) {
    n = { name: "home" };
  }
  next(n!);
});

// router.afterEach((to, from) => {
//   // 地图页面
//   const mapPage = ["chain", "xlnt", "companyChain", "carrier"];
//   if (mapPage.indexOf(to.name as string) >= 0) {
//     if (JSON.stringify(to)) {
//       console.log(JSON.stringify(to));
//     }
//     // window.location.href = to.fullPath;
//     // window.location.reload();
//   }
// });

export default router;
