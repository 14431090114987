import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_n_time = _resolveComponent("n-time");
  const _component_n_tag = _resolveComponent("n-tag");
  return _openBlock(), _createBlock(_component_n_tag, {
    size: "small",
    bordered: false,
    color: {
      color: 'transparent'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_n_time, {
      time: _ctx.time,
      class: "lb-clock"
    }, null, 8, ["time"])]),
    _: 1
  });
}