import { defineComponent, reactive, onMounted, toRefs } from 'vue';
import tEditor from "@/component/peculiar/tool/editor.vue";
export default defineComponent({
  components: {
    tEditor
  },
  props: {
    data: {
      type: Object,
      default: {}
    },
    datas: {
      type: Array,
      default: []
    }
  },
  setup(props, {
    emit
  }) {
    const form = reactive({
      ref: null,
      refs: {},
      dRef: e => {
        if (e && e.$attrs.name) {
          form.refs[e.$attrs.name] = e;
        }
      },
      // 绑定数据
      models: {},
      // 验证规则
      rules: {},
      // 初始化
      init: () => {
        const data = props.data;
        const datas = props.datas;
        datas.forEach(d => {
          if (d.type === "checkbox-tree") {
            form.refs[d.name] = null;
          }
          form.models[d.name] = data[d.name] || data[d.name] === false || data[d.name] === 0 ? data[d.name] : d.value;
          form.rules[d.name] = d.rules;
        });
      },
      // 更新checked tree值
      updTreeChecked: (name, keys) => {
        console.log(keys);
      },
      getValue: name => {
        return form.refs[name].getValue();
      },
      submit: () => {
        form.ref.validate(async errors => {
          // 验证通过
          if (!errors) {
            const datas = props.datas;
            datas.forEach(d => {
              // checkbox-tree value
              if (d.type === "checkbox-tree") {
                const tree = form.refs[d.name];
                form.models[d.name] = [...tree.getCheckedData().keys, ...tree.getIndeterminateData().keys];
              }
            });
            const formData = {
              ...form.models
            };
            emit("submit", formData);
          }
        });
      }
    });
    onMounted(() => {
      form.init();
    });
    return {
      ...toRefs(form)
    };
  }
});