import { defineComponent, reactive, computed, onMounted, toRefs } from 'vue';
import { useStore } from 'vuex';
import "core-js/modules/es.array.push.js";
// import { getIndTs } from "@/util/area";
import { getIndTs } from "@/util/industry";
export default defineComponent({
  components: {},
  props: {
    preset: {
      type: Object,
      default: {}
    },
    data: {
      type: Object,
      default: {}
    }
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const options = reactive({
      // 地区
      area: computed(() => {
        const areas = store.getters.areas;
        sf.setArea(areas);
        return areas;
      }),
      // 产业
      indt: computed(() => {
        const indt = store.getters.indt.length > 0 ? getIndTs() : [];
        sf.setIndt(indt);
        return indt;
      })
    });
    const sf = reactive({
      refs: {},
      dRef: e => {
        if (e && e.getAttribute("name")) {
          sf.refs[e.getAttribute("name")] = e;
        }
      },
      // 折叠
      show: true,
      // label width
      lw: 100,
      // 已选参数
      params: [],
      // 所有筛选条件
      filters: [{
        show: true,
        fold: true,
        exceed: false,
        type: "radio",
        name: "province",
        label: "省份",
        value: "",
        options: []
      }, {
        show: false,
        fold: true,
        exceed: false,
        type: "radio",
        name: "city",
        label: "城市",
        value: "",
        options: []
      }, {
        show: false,
        fold: true,
        exceed: false,
        type: "radio",
        name: "district",
        label: "区县",
        value: "",
        options: []
      }, {
        show: true,
        fold: true,
        exceed: false,
        type: "radio",
        name: "industry",
        label: "行业大类",
        value: "",
        options: []
      }, {
        show: false,
        fold: true,
        exceed: false,
        type: "radio",
        name: "industrySub",
        label: "行业",
        value: "",
        options: []
      }, {
        show: true,
        fold: true,
        exceed: false,
        type: "radio",
        name: "date",
        label: "成立时间",
        value: "",
        options: [{
          label: "5年内",
          value: "5--0"
        }, {
          label: "5-10年",
          value: "10--5"
        }, {
          label: "10-15年",
          value: "15--10"
        }, {
          label: "15年以上",
          value: "--15"
        }]
      }, {
        show: true,
        fold: true,
        exceed: false,
        type: "radio",
        name: "capitalR",
        label: "注册资金",
        value: "",
        options: [{
          label: "100万内",
          value: "0--100"
        }, {
          label: "100万-200万",
          value: "100--200"
        }, {
          label: "200万-500万",
          value: "200--500"
        }, {
          label: "500万-1000万",
          value: "500--1000"
        }, {
          label: "1000万以上",
          value: "1000--"
        }]
      }, {
        show: true,
        fold: true,
        exceed: false,
        type: "radio",
        name: "capitalType",
        label: "资本类型",
        value: "",
        options: [{
          label: "人民币",
          value: "1"
        }, {
          label: "美元",
          value: "2"
        }, {
          label: "其他",
          value: "3"
        }]
      }, {
        show: true,
        fold: true,
        exceed: false,
        type: "radio",
        name: "status",
        label: "企业状态",
        value: "",
        options: [{
          label: "在业",
          value: "在业"
        }, {
          label: "存续",
          value: "存续"
        }, {
          label: "吊销",
          value: "吊销"
        }, {
          label: "注销",
          value: "注销"
        }, {
          label: "迁出",
          value: "迁出"
        }]
      }],
      // 绑定数据
      models: {},
      // 设置地区
      setArea: areas => {
        sf.filters[0].options = areas.slice(1);
      },
      // 设置行业
      setIndt: indt => {
        sf.filters[3].options = indt;
      },
      // 初始化
      init: (clear = false) => {
        // base
        const data = clear ? props.preset : props.data;
        sf.filters.forEach(f => {
          sf.models[f.name] = data[f.name] !== undefined ? data[f.name] : f.value;
        });
      },
      // 值变化
      update: () => {
        const params = [];
        sf.filters.forEach(f => {
          const value = sf.models[f.name];
          let valueStr = "";
          // if (f.type === "area") {
          //   valueStr = getAreaName(value);
          // } else if (f.type === "industry") {
          //   valueStr = getIndHName(value);
          // } else if (f.type === "input") {
          //   valueStr = value;
          // }
          if (f.type === "radio") {
            const option = f.options.find(o => o.value === value);
            if (option) {
              valueStr = option.label;
            }
            // 设置省份
            if (f.name === "province") {
              if (value) {
                sf.filters[0].show = false;
                const provOptions = sf.filters[0].options;
                const prov = provOptions.find(p => p.value === value);
                if (prov && prov.children) {
                  const firstCode = prov.children[0].code * 1;
                  const isCity = Math.floor(firstCode / 100) * 100 === firstCode;
                  const childrenKey = isCity ? 1 : 2;
                  sf.filters[childrenKey].options = prov.children;
                  sf.filters[childrenKey].show = true;
                }
              } else {
                sf.filters[0].show = true;
                sf.models[sf.filters[1].name] = sf.filters[1].value;
                sf.filters[1].options = [];
                sf.filters[1].show = false;
                sf.models[sf.filters[2].name] = sf.filters[2].value;
                sf.filters[2].options = [];
                sf.filters[2].show = false;
              }
            }
            // 设置城市
            else if (f.name === "city") {
              if (value) {
                sf.filters[1].show = false;
                const cityOptions = sf.filters[1].options;
                const city = cityOptions.find(c => c.value === value);
                if (city && city.children) {
                  sf.filters[2].options = city.children;
                  sf.filters[2].show = true;
                }
              } else {
                // 直辖市
                if (sf.filters[1].options.length === 0 && sf.filters[2].options.length > 0) {
                  sf.filters[1].show = false;
                  sf.filters[2].show = true;
                } else {
                  sf.models[sf.filters[1].name] = sf.filters[1].value;
                  if (sf.filters[1].options.length > 0) {
                    sf.filters[1].show = true;
                  }
                  sf.models[sf.filters[2].name] = sf.filters[2].value;
                  sf.filters[2].options = [];
                  sf.filters[2].show = false;
                }
              }
            }
            // 设置行业
            else if (f.name === "industry") {
              if (value) {
                sf.filters[3].show = false;
                const indOptions = sf.filters[3].options;
                const ind = indOptions.find(i => i.value === value);
                if (ind && ind.children) {
                  sf.filters[4].options = ind.children;
                  sf.filters[4].show = true;
                }
              } else {
                sf.models[sf.filters[4].name] = sf.filters[4].value;
                sf.filters[3].show = true;
                sf.filters[4].show = false;
              }
            }
          }
          if (JSON.stringify(f.value) != JSON.stringify(value)) {
            params.push({
              name: f.name,
              value: value,
              valueStr: f.label + "：" + valueStr
            });
          }
        });
        sf.params = params;
        emit("filter", sf.models);
      },
      // 移除条件
      remove: p => {
        const filter = sf.filters.find(f => f.name === p.name);
        if (filter) {
          sf.models[p.name] = filter.value;
        }
        sf.update();
      },
      // 重置条件
      reset: () => {
        sf.init(true);
        sf.update();
      }
    });
    // monitor
    const monitor = reactive({
      // 筛选区域
      obsFilter: null,
      // 启用监听
      monitor: () => {
        if (monitor.obsFilter) {
          new ResizeObserver(() => {
            monitor.setSize();
          }).observe(monitor.obsFilter);
        }
      },
      // 设置尺寸
      setSize: () => {
        if (monitor.obsFilter) {
          for (const key in sf.refs) {
            const exceed = sf.refs[key].offsetHeight > 24;
            const filter = sf.filters.find(f => f.name === key);
            if (filter) {
              filter.exceed = exceed;
            }
          }
        }
      }
    });
    onMounted(() => {
      // 元素监听
      monitor.monitor();
      sf.init();
      sf.update();
    });
    return {
      ...toRefs(options),
      ...toRefs(sf),
      ...toRefs(monitor)
    };
  }
});