import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_n_input = _resolveComponent("n-input");
  const _component_n_form_item = _resolveComponent("n-form-item");
  const _component_n_button = _resolveComponent("n-button");
  const _component_n_space = _resolveComponent("n-space");
  const _component_n_form = _resolveComponent("n-form");
  return _openBlock(), _createBlock(_component_n_form, {
    ref: "ref",
    "label-placement": "left",
    model: _ctx.models,
    rules: _ctx.rules
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, i => {
      return _openBlock(), _createBlock(_component_n_form_item, {
        path: i.name
      }, {
        default: _withCtx(() => [_createVNode(_component_n_input, {
          clearable: "",
          size: "large",
          type: "password",
          "show-password-on": "mousedown",
          disabled: _ctx.loading,
          value: _ctx.models[i.name],
          "onUpdate:value": $event => _ctx.models[i.name] = $event,
          placeholder: i.placeholder,
          onKeydown: _withKeys(_ctx.submit, ["enter"])
        }, null, 8, ["disabled", "value", "onUpdate:value", "placeholder", "onKeydown"])]),
        _: 2
      }, 1032, ["path"]);
    }), 256)), _createVNode(_component_n_space, {
      justify: "center"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.btns, b => {
        return _openBlock(), _createBlock(_component_n_button, {
          type: b.type,
          onClick: b.click
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(b.label), 1)]),
          _: 2
        }, 1032, ["type", "onClick"]);
      }), 256))]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"]);
}