import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_n_data_table = _resolveComponent("n-data-table");
  return _ctx.remote ? (_openBlock(), _createBlock(_component_n_data_table, {
    key: 0,
    striped: "",
    remote: _ctx.remote,
    size: _ctx.size,
    "scroll-x": _ctx.width,
    "max-height": _ctx.height,
    "single-line": true,
    columns: _ctx.columns,
    data: _ctx.datas,
    "row-key": row => row[_ctx.rowKey],
    pagination: _ctx.pagination,
    "onUpdate:page": _ctx.change
  }, null, 8, ["remote", "size", "scroll-x", "max-height", "columns", "data", "row-key", "pagination", "onUpdate:page"])) : (_openBlock(), _createBlock(_component_n_data_table, {
    key: 1,
    striped: "",
    size: _ctx.size,
    "scroll-x": _ctx.width,
    "max-height": _ctx.height,
    "single-line": true,
    columns: _ctx.columns,
    data: _ctx.datas,
    "row-key": row => row[_ctx.rowKey],
    pagination: _ctx.pagination
  }, null, 8, ["size", "scroll-x", "max-height", "columns", "data", "row-key", "pagination"]));
}