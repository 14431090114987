import { defineComponent, reactive, computed, shallowRef, nextTick, watch, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
import * as echarts from "echarts";
export default defineComponent({
  components: {},
  props: {
    maxHeight: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: {}
    }
  },
  setup(props, {
    emit
  }) {
    const tool = reactive({
      hTool: 20,
      hMax: 0,
      countL: 2,
      setHMax: () => {
        tool.hMax = props.maxHeight;
      },
      unit: computed(() => {
        return props.data.unit;
      }),
      lg: (direction, colors) => {
        const k1 = direction === "rl" ? 1 : 0;
        const k2 = direction === "bt" ? 1 : 0;
        const k3 = direction === "lr" ? 1 : 0;
        const k4 = direction === "tb" ? 1 : 0;
        const interval = colors.length - 1 > 0 ? 1 / (colors.length - 1) : 0;
        const colorBox = [];
        colors.forEach((c, idx) => {
          colorBox.push({
            offset: c[1] ? c[1] : idx * interval,
            color: c[0]
          });
        });
        return new echarts.graphic.LinearGradient(k1, k2, k3, k4, colorBox);
      }
    });
    const chart = reactive({
      ready: computed(() => {
        chart.draw();
        return Object.keys(props.data).length > 0;
      }),
      enter: true,
      height: 0,
      iHeight: 60,
      // 对象
      ref: null,
      // 实例
      chart: shallowRef(),
      // 配置
      opts: {
        // tooltip
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          backgroundColor: "rgba(17, 95, 182, 0.5)",
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          },
          formatter: `{b}<br/>{a}: {c}${tool.unit}`
        },
        grid: {
          show: false,
          left: 0,
          right: 60,
          top: 0,
          bottom: 0,
          containLabel: true
        },
        xAxis: [],
        yAxis: [],
        series: []
      },
      // 画
      draw: () => {
        nextTick(() => {
          chart.monitor();
        });
      },
      // 数据处理
      process: () => {
        const datas = props.data.datas;
        const list = datas[datas.length - 1].list;
        // 行
        const maxRows = Math.floor((props.maxHeight - tool.hTool) / chart.iHeight);
        const rows = Math.min(list.length, maxRows);
        // 高度
        const height = rows * chart.iHeight + tool.hTool;
        chart.height = height;
        // X轴
        const xAxis = [];
        // Y轴
        const yAxis = [];
        // 数据
        const series = [];
        /**
         * 核心数据
         */
        datas.forEach((c, idx) => {
          const yDatas = [];
          const sDatas = [];
          c.list.forEach((i, cidx) => {
            if (cidx < rows) {
              yDatas[cidx] = i.name;
              sDatas[cidx] = i.value !== undefined ? i.value : 0;
            }
          });
          // 双倍图层（常规、数值）
          for (let n = 0; n < tool.countL; n++) {
            // X
            const x = {
              show: false
            };
            xAxis.push(x);
            // Y
            const y = {
              type: "category",
              inverse: true,
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false
              },
              data: yDatas
            };
            yAxis.push(y);
            // series
            series.push(chart.setS(c, sDatas, n));
          }
        });
        chart.opts.xAxis = xAxis;
        chart.opts.yAxis = yAxis;
        chart.opts.series = series;
      },
      // 设置series
      setS: (c, datas, idx) => {
        const seriesBase = {
          name: c.label,
          type: "bar",
          barWidth: 20,
          xAxisIndex: idx,
          yAxisIndex: idx,
          data: datas
        };
        const series = {
          ...seriesBase
        };
        if (idx === 0) {
          series.label = {
            show: true,
            formatter: "{b}",
            position: [0, "-100%"],
            offset: [0, 2],
            fontSize: 14
          };
        } else if (idx === 1) {
          series.barGap = "-100%";
          series.label = {
            show: true,
            formatter: i => {
              return i.data > 0 ? `${i.data}${tool.unit}` : "";
            },
            position: "right"
          };
        }
        return series;
      },
      // 渲染
      render: () => {
        // 已渲染
        if (chart.ref && chart.chart) {
          chart.chart.setOption(chart.opts, true);
          chart.rerender();
        } else if (chart.ref) {
          chart.chart = echarts.init(chart.ref);
          chart.chart.setOption(chart.opts);
          // window resize
          window.addEventListener("resize", () => {
            chart.rerender();
          });
          // finish
          chart.chart.on("finished", () => {
            // finished
          });
          // click
          chart.chart.on("click", e => {
            const datas = props.data.datas;
            const s = Math.floor(e.seriesIndex / tool.countL);
            const i = e.dataIndex;
            if (datas[s] && datas[s].list[i]) {
              emit("get-i", datas[s].list[i]);
            }
          });
        }
      },
      // 重新渲染
      rerender: () => {
        chart.chart.resize(chart.ref);
      },
      // 销毁
      destroy: () => {
        chart.chart.dispose();
      },
      // 监听元素尺寸
      monitor: () => {
        new ResizeObserver(() => {
          if (chart.ref) {
            const height = chart.ref.offsetHeight;
            // 进入操作结束
            if (chart.enter === true && chart.height !== height) {
              chart.enter = false;
            }
            // 进入||高度变化
            if (chart.enter === true || chart.height !== height) {
              chart.process();
              setTimeout(() => {
                chart.render();
              }, 500);
            }
            // 高度无变化
            else {
              chart.rerender();
            }
          }
        }).observe(chart.ref);
      }
    });
    watch(() => props.maxHeight, () => {
      chart.process();
    });
    return {
      ...toRefs(chart)
    };
  }
});