import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/store";
import router from "@/router";

const isJson = (value: any) => {
  let result = false;
  if (Object.prototype.toString.call(value) === "[object String]") {
    try {
      const object = JSON.parse(value);
      if (Object.prototype.toString.call(object) === "[object Object]") {
        result = true;
      }
    } catch (e) {
      result = false;
    }
  }
  return result;
};

const service = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? 'https://v2.bsicbigdata.com/apiv2'
      : "http://192.168.124.112:8800",
  // baseURL: "http://192.168.124.112:8800",
  headers: { "Content-Type": "application/json;charset=utf-8" },
  withCredentials: false,
  timeout: 3000000,
  transformRequest: [
    (data) => {
      if (!isJson(data)) {
        data = JSON.stringify(data);
      }
      return data;
    },
  ],
  validateStatus(status) {
    return status === 200;
  },
  transformResponse: [
    (data) => {
      if (isJson(data)) {
        data = JSON.parse(data);
      }
      return data;
    },
  ],
});
// 请求拦截
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const tokenValid = Object.keys(store.getters.token).length > 0;
    if (tokenValid) {
      const token = store.getters.token;
      config.headers![token.name] = token.value;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 请求响应
service.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      response.status === 200 &&
      response.data.code !== 502 &&
      response.data.code !== 503 &&
      response.data.code !== 504
    ) {
      return response.data;
    } else if (response.data.code === 503) {
      router.push({ name: "login" });
    } else if (response.data.code === 502 || response.status === 401) {
      // logout
      store.commit("setD", { key: "token", value: {} });
      store.commit("setD", { key: "user", value: {} });
      router.currentRoute.value.name !== "login" &&
        router.replace({
          name: "login",
          query: { redirect: router.currentRoute.value.path },
        });
      window.location.reload();
      // return response.data;
    } else {
      return Promise.reject(new Error(serviceError(response.status)));
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

const serviceError = (status: number) => {
  let error = `网络连接错误(${status})!`;
  const dictionaries: Record<number, string> = {
    401: "未授权，请重新登录(401)",
    403: "拒绝访问(403)",
    404: "请求出错(404)",
    408: "请求超时(408)",
    500: "服务器错误(500)",
    501: "服务未实现(501)",
    502: "网络错误(502)",
    503: "服务不可用(503)",
    504: "网络超时(504)",
    505: "HTTP版本不受支持(505)",
  };
  if (dictionaries[status] !== undefined) {
    error = dictionaries[status];
  }
  return `${error}，请联系管理员！`;
};

export async function request(method: string, url: string, param = {}) {
  let request: any = undefined;
  if (method === "g") {
    request = service.get(url, { params: param });
  } else {
    request = service.post(url, param);
  }
  const promise = new Promise((resolve, reject) => {
    request
      .then((response: Record<string, any>) => {
        if (response.code === 200) {
          resolve({ status: true, data: response.data });
        } else {
          reject(new Error(response.msg));
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
  try {
    const data: any = await promise;
    return data;
  } catch (error: any) {
    return {
      status: false,
      data: error.message,
    };
  }
}

export async function file(url: string, name: string, data = {}) {
  const request = service.post(url, data, {responseType: 'blob'});
  const promise = new Promise((resolve, reject) => {
    request
      .then((response: any) => {
        if (Object.prototype.toString.call(response) === "[object Blob]") {
          const url = window.URL.createObjectURL(response);
          const a = document.createElement("a");
          a.href = url;
          a.download = name;
          a.click();
          window.URL.revokeObjectURL(url);
          resolve({ status: true, data: "" });
        } else {
          reject(new Error("下载失败"));
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
  try {
    const data: any = await promise;
    return data;
  } catch (error: any) {
    return {
      status: false,
      data: error.message,
    };
  }
}

export function baseUrl() {
  return service.defaults.baseURL;
}
