import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_n_input = _resolveComponent("n-input");
  const _component_t_editor = _resolveComponent("t-editor");
  const _component_n_switch = _resolveComponent("n-switch");
  const _component_n_checkbox = _resolveComponent("n-checkbox");
  const _component_n_gi = _resolveComponent("n-gi");
  const _component_n_grid = _resolveComponent("n-grid");
  const _component_n_checkbox_group = _resolveComponent("n-checkbox-group");
  const _component_n_select = _resolveComponent("n-select");
  const _component_n_tree_select = _resolveComponent("n-tree-select");
  const _component_n_tree = _resolveComponent("n-tree");
  const _component_n_cascader = _resolveComponent("n-cascader");
  const _component_n_form_item_gi = _resolveComponent("n-form-item-gi");
  const _component_n_form = _resolveComponent("n-form");
  return _openBlock(), _createBlock(_component_n_form, {
    ref: "ref",
    "label-align": "left",
    model: _ctx.models,
    rules: _ctx.rules
  }, {
    default: _withCtx(() => [_createVNode(_component_n_grid, {
      "item-responsive": "",
      "x-gap": 10,
      "y-gap": 10
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datas, d => {
        return _openBlock(), _createBlock(_component_n_form_item_gi, {
          span: d.span ? d.span : 24,
          label: d.label,
          path: d.name
        }, {
          default: _withCtx(() => [d.type === 'input' ? (_openBlock(), _createBlock(_component_n_input, {
            key: 0,
            clearable: "",
            value: _ctx.models[d.name],
            "onUpdate:value": $event => _ctx.models[d.name] = $event,
            placeholder: d.placeholder
          }, null, 8, ["value", "onUpdate:value", "placeholder"])) : _createCommentVNode("", true), d.type === 'password' ? (_openBlock(), _createBlock(_component_n_input, {
            key: 1,
            clearable: "",
            type: "password",
            "show-password-on": "mousedown",
            value: _ctx.models[d.name],
            "onUpdate:value": $event => _ctx.models[d.name] = $event,
            placeholder: d.placeholder
          }, null, 8, ["value", "onUpdate:value", "placeholder"])) : d.type === 'textarea2' ? (_openBlock(), _createBlock(_component_n_input, {
            key: 2,
            clearable: "",
            type: "textarea",
            autosize: {
              minRows: d.rows ? d.rows : 10
            },
            value: _ctx.models[d.name],
            "onUpdate:value": $event => _ctx.models[d.name] = $event,
            placeholder: d.placeholder
          }, null, 8, ["autosize", "value", "onUpdate:value", "placeholder"])) : d.type === 'textarea' ? (_openBlock(), _createBlock(_component_t_editor, {
            key: 3,
            ref_for: true,
            ref: _ctx.dRef,
            name: d.name,
            content: _ctx.models[d.name]
          }, null, 8, ["name", "content"])) : d.type === 'switch' ? (_openBlock(), _createBlock(_component_n_switch, {
            key: 4,
            round: false,
            value: _ctx.models[d.name],
            "onUpdate:value": $event => _ctx.models[d.name] = $event
          }, null, 8, ["value", "onUpdate:value"])) : d.type === 'checkbox' ? (_openBlock(), _createBlock(_component_n_checkbox_group, {
            key: 5,
            style: {
              width: '100%'
            },
            value: _ctx.models[d.name],
            "onUpdate:value": $event => _ctx.models[d.name] = $event
          }, {
            default: _withCtx(() => [_createVNode(_component_n_grid, {
              "y-gap": 8,
              cols: d.options?.length > 4 ? 4 : d.options?.length
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(d.options, o => {
                return _openBlock(), _createBlock(_component_n_gi, null, {
                  default: _withCtx(() => [_createVNode(_component_n_checkbox, {
                    value: o.value,
                    label: o.label
                  }, null, 8, ["value", "label"])]),
                  _: 2
                }, 1024);
              }), 256))]),
              _: 2
            }, 1032, ["cols"])]),
            _: 2
          }, 1032, ["value", "onUpdate:value"])) : d.type === 'select' ? (_openBlock(), _createBlock(_component_n_select, {
            key: 6,
            clearable: "",
            filterable: "",
            options: d.options,
            value: _ctx.models[d.name],
            "onUpdate:value": $event => _ctx.models[d.name] = $event,
            placeholder: d.placeholder,
            "render-label": d.renderLabel
          }, null, 8, ["options", "value", "onUpdate:value", "placeholder", "render-label"])) : d.type === 'select-tree' ? (_openBlock(), _createBlock(_component_n_tree_select, {
            key: 7,
            multiple: d.multiple,
            clearable: "",
            filterable: "",
            "default-expand-all": "",
            options: d.options,
            value: _ctx.models[d.name],
            "onUpdate:value": $event => _ctx.models[d.name] = $event,
            placeholder: d.placeholder
          }, null, 8, ["multiple", "options", "value", "onUpdate:value", "placeholder"])) : d.type === 'checkbox-tree' ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 8
          }, [_ctx.models[d.name] ? (_openBlock(), _createBlock(_component_n_tree, {
            key: 0,
            ref_for: true,
            ref: _ctx.dRef,
            name: d.name,
            "block-line": "",
            cascade: "",
            checkable: "",
            "check-on-click": "",
            "default-expand-all": "",
            data: d.options,
            "default-checked-keys": _ctx.models[d.name]
          }, null, 8, ["name", "data", "default-checked-keys"])) : _createCommentVNode("", true)], 64)) : d.type === 'area' ? (_openBlock(), _createBlock(_component_n_cascader, {
            key: 9,
            clearable: "",
            filterable: "",
            "expand-trigger": "hover",
            "value-field": "code",
            value: _ctx.models[d.name],
            "onUpdate:value": $event => _ctx.models[d.name] = $event,
            placeholder: d.placeholder,
            options: d.options
          }, null, 8, ["value", "onUpdate:value", "placeholder", "options"])) : _createCommentVNode("", true)]),
          _: 2
        }, 1032, ["span", "label", "path"]);
      }), 256))]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"]);
}